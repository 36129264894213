import {
	CountryCode,
	IPublicTransaction,
	paymentGateway,
	TransactionId,
	TransactionPagination,
	transactionStatus,
	TransactionStatus,
} from '@faceyourmanga/fym-lib'
import React from 'react'
import { IServerPagination } from '@faceyourmanga/fym-shared'
import { nameCountryMap } from '@faceyourmanga/fym-lib/dist/base/CurrencyMap'

interface IPaymentsPaginatorBarProps {
	paginator: IServerPagination<IPublicTransaction, TransactionPagination>
}

function PaymentsPaginatorBar(props: IPaymentsPaginatorBarProps) {
	const count = props.paginator.getCount()
	const page = props.paginator.getPage()

	return (
		<div className="paginatorBar">
			<div className="paginatorBar__dates">
				id{' '}
				<input
					type="string"
					value={props.paginator.getQuery().id}
					onChange={e => props.paginator.query({ id: e.target.value as TransactionId })}
				/>
			</div>
			<div className="paginatorBar__status">
				status{' '}
				<select
					value={props.paginator.getQuery().status}
					onChange={e => props.paginator.query({ status: e.target.value as TransactionStatus })}
				>
					<option></option>
					{transactionStatus.map(status => (
						<option key={status} value={status}>
							{status}
						</option>
					))}
				</select>
			</div>
			<div className="paginatorBar__nationality">
				nationality{' '}
				<select
					value={props.paginator.getQuery().country}
					onChange={e => props.paginator.query({ country: e.target.value as CountryCode })}
				>
					<option></option>
					{Object.keys(nameCountryMap).map(countryCode => (
						<option key={countryCode} value={countryCode}>
							{nameCountryMap[countryCode as CountryCode]}
						</option>
					))}
				</select>
			</div>
			<div className="paginatorBar__dates">
				from{' '}
				<input
					type="date"
					value={new Date(props.paginator.getQuery().startDate || Date.now()).toISOString().substr(0, 10)}
					onChange={e => {
						props.paginator.query({ startDate: Date.parse(e.target.value) })
					}}
				/>{' '}
				to{' '}
				<input
					type="date"
					value={new Date(props.paginator.getQuery().endDate || Date.now()).toISOString().substr(0, 10)}
					onChange={e => {
						props.paginator.query({ endDate: Date.parse(e.target.value) })
					}}
				/>
			</div>
			<div className="paginatorBar__gateways">
				{paymentGateway.map(gateway => (
					<span key={gateway}>
						{gateway}{' '}
						<input
							type="checkbox"
							onChange={() => {
								const cg = props.paginator.getQuery().gateways as unknown as string | Array<string>
								const g = typeof cg === 'string' ? cg.split(',') : cg
								if (!g || g.length === 0) {
									props.paginator.query({ gateways: [gateway] })
								} else {
									if (g.includes(gateway)) g.splice(g.indexOf(gateway), 1)
									else g.push(gateway)

									//@ts-ignore
									props.paginator.query({ gateways: g })
								}
							}}
							checked={props.paginator.getQuery().gateways?.includes(gateway)}
						/>
					</span>
				))}
			</div>
			<div className="paginatorBar__size">
				for page:
				<select
					value={props.paginator.getSize()}
					onChange={e => {
						props.paginator.setSize(parseInt(e.target.value))
					}}
				>
					<option value={5}>5</option>
					<option value={10}>10</option>
					<option value={25}>25</option>
					<option value={100}>100</option>
					<option value={200}>200</option>
					<option value={1000}>1000</option>
					<option value={10000}>10000</option>
				</select>
			</div>
			<div className="paginatorBar__page">
				page:
				<select value={page} onChange={e => props.paginator.goTo(parseInt(e.target.value))}>
					{new Array(count).fill(1).map((v, i) => (
						<option value={i + 1}>{i + 1}</option>
					))}
				</select>
			</div>
		</div>
	)
}

export default React.memo(PaymentsPaginatorBar)
