import * as React from 'react'

import { IResourceForm } from '../../components/CreateUpdateResource'
import BaseForm from './BaseForm'

const SetForm = (props: IResourceForm) => {
	return <BaseForm {...props} />
}

export default SetForm
