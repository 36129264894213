import React from 'react'
import { IconContext } from 'react-icons'
import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'

import { Auth, ISharedHostsState } from '@faceyourmanga/fym-shared'

import { IRootState, IWithExistUser, IWithUser } from '../types'
import store from '../redux/root'
import { bindServices, stopServices } from '../redux/bindings'

import Messages from './messages/Messages'
import Router from './Router'
import { IUser } from '@faceyourmanga/fym-lib'

interface IAppProps extends IWithUser {
	hosts: ISharedHostsState
}

function App(props: IAppProps) {
	React.useEffect(() => {
		if (props.user && props.hosts.ws.length > 0) {
			bindServices(store)
		}
	}, [props.user, props.hosts])

	React.useEffect(() => {
		if (!props.user) {
			stopServices(store)
		}
	}, [props.user, props.hosts])

	if (!Auth.check()) {
		return <Redirect to="/login" />
	}

	if (Auth.getUser()?.scopes.length === 0) {
		Auth.signout()
	}

	return (
		<main className="app">
			<header className="app__head">
				<img className="app__head__logo" src="/assets/images/logo.svg" alt="face your manga" />

				<AppNav user={props.user as IUser} />

				<AppUserProfile user={props.user as IUser} />
			</header>

			<IconContext.Provider value={{ className: 'react-icons' }}>
				<section className="app__content">
					<Router user={props.user as IUser} />
				</section>
			</IconContext.Provider>

			<Messages />
		</main>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		user: state.auth.user,
		hosts: state.hosts,
	}))(App)
)

//////////////////////////////

const AppNav = React.memo(({ user }: IWithExistUser) => {
	const admin = user.scopes.includes('admin')

	return (
		<nav className="app__head__nav">
			{(admin || user.scopes.includes('editor')) && (
				<NavLink activeClassName="app__head__nav__link--active" className="app__head__nav__link" to="/creators">
					creators
				</NavLink>
			)}
			{(admin || user.scopes.includes('moderator')) && (
				<NavLink activeClassName="app__head__nav__link--active" className="app__head__nav__link" to="/users">
					users
				</NavLink>
			)}
			{(admin || user.scopes.includes('business')) && (
				<NavLink activeClassName="app__head__nav__link--active" className="app__head__nav__link" to="/payments">
					payments
				</NavLink>
			)}
		</nav>
	)
})

//////////////////////////////

const AppUserProfile = React.memo((props: IWithUser) => {
	return (
		<div className="app__head__user">
			{props.user && (
				<div>
					{props.user.email}
					<button onClick={() => Auth.signout()}>logout</button>
				</div>
			)}
		</div>
	)
})
