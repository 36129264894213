import {
	ProtocolResources,
	Data,
	ProtocolResourcesCreated,
	ProtocolResourcesChanged,
	ProtocolResourcesRemoved,
	IMixed,
} from '@faceyourmanga/fym-lib/dist'
import { Store } from 'redux'
import { addResource, updateResource, removeResource } from '../resources/actions'

import { ClientProto } from '@faceyourmanga/fym-lib'

export default class ProtoResources extends ClientProto<ProtocolResources> {
	private store: Store

	constructor(store: Store) {
		super('protocolResources', ['created', 'changed', 'removed'])

		this.store = store
	}

	onAction(action: ProtocolResources['action'], data: Data<ProtocolResources>) {
		switch (action) {
			case 'created':
				return this.onActionCreated(data as Data<ProtocolResourcesCreated>)

			case 'changed':
				return this.onActionChanged(data as Data<ProtocolResourcesChanged>)

			case 'removed':
				return this.onActionRemoved(data as Data<ProtocolResourcesRemoved>)
		}
	}

	onActionCreated(data: Data<ProtocolResourcesCreated>) {
		this.store.dispatch(addResource(data.name, data.resource))
	}

	onActionChanged(data: Data<ProtocolResourcesChanged>) {
		this.store.dispatch(updateResource(data.name, data.resource))
	}

	onActionRemoved(data: Data<ProtocolResourcesRemoved>) {
		this.store.dispatch(removeResource(data.name, { id: data.id } as IMixed))
	}
}
