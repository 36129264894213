import { IMixed, IResources } from '@faceyourmanga/fym-lib'

import {
	ResourcesActionTypes,
	IMPORT_RESOURCES_STATE,
	ADD_RESOURCE,
	REMOVE_RESOURCE,
	UPDATE_RESOURCE,
	UPDATE_RESOURCES,
} from './types'

export function importResourcesState(resources: IResources): ResourcesActionTypes {
	return {
		type: IMPORT_RESOURCES_STATE,
		resources,
	}
}

export function addResource(item_type: keyof IResources, item: IMixed): ResourcesActionTypes {
	return {
		type: ADD_RESOURCE,
		item_type,
		item,
	}
}
export function updateResource(item_type: keyof IResources, item: IMixed): ResourcesActionTypes {
	return {
		type: UPDATE_RESOURCE,
		item_type,
		item,
	}
}

export function updateResources(item_type: keyof IResources, items: Array<IMixed>): ResourcesActionTypes {
	return {
		type: UPDATE_RESOURCES,
		item_type,
		items,
	}
}

export function removeResource(item_type: keyof IResources, item: IMixed): ResourcesActionTypes {
	return {
		type: REMOVE_RESOURCE,
		item_type,
		item,
	}
}
