import { IElement, IResources } from '@faceyourmanga/fym-lib/dist'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { IRootState } from '../../../types/store'
import CreatorElement from './resources/CreatorElement'
import CreatorPalette from './resources/CreatorPalette'
import CreatorTransform from './resources/CreatorTransform'

import CreatorResources from './resources/CreatorResources'

interface ICreatorsProps {
	resources: IResources
}

function Creators(props: RouteComponentProps<{}> & ICreatorsProps) {
	const [newElement, setNewElement] = React.useState<Partial<IElement> | null>(null)
	const [openPalette, setOpenPalette] = React.useState<boolean>(false)
	const [openTransform, setOpenTransform] = React.useState<boolean>(false)

	function openCreateElement(element: IElement | undefined, categoryId?: string) {
		if (element) setNewElement(element)
		else if (categoryId) setNewElement({ categories: [categoryId] })
		else setNewElement({})
	}

	return (
		<section className="creators">
			<section className="creators__content">
				<CreatorResources resources={props.resources} createUpdateElement={openCreateElement} />
			</section>

			<aside className="creators__info">
				<div className="creators__info__item">
					<b>{Object.keys(props.resources.set).length}</b>
					Sets
				</div>

				<div className="creators__info__item">
					<b>{Object.keys(props.resources.group).length}</b>
					Groups
				</div>

				<div className="creators__info__item">
					<b>{Object.keys(props.resources.category).length}</b>
					Categories
				</div>

				<div className="creators__info__item">
					<b>{Object.keys(props.resources.element).length}</b>
					Elements
				</div>

				{/* <button onClick={() => openCreateElement(undefined, undefined)}>Add Element</button> */}
			</aside>

			<footer className="creators__footer">
				<span onClick={() => !openPalette && setOpenPalette(true)}>Gestione Palette</span>
				<span onClick={() => !openTransform && setOpenTransform(true)}>Gestione Transform</span>
			</footer>

			<CreatorElement newElement={newElement} setNewElement={setNewElement} />
			<CreatorPalette open={openPalette} setOpen={setOpenPalette} />
			<CreatorTransform open={openTransform} setOpen={setOpenTransform} />
		</section>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		resources: state.resources,
	}))(Creators)
)
