import { DrawerController, Renderer } from '@faceyourmanga/fym-draw'
import { CategoryId, ElementId, IElement, IResourcesMap, ISet, ITransform } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { RiCloseLine } from 'react-icons/ri'
import { connect } from 'react-redux'
import { blobToBase64 } from '../../../../extension/blob'
import { IRootState } from '../../../../types/store'
import Flex from '../../../components/Flex'
import RawCanvas from '../../../components/RawCanvas'
import ResourcePathSelector from '../../../components/ResourcePath/ResourcePathSelector'
import dispatchMessage from '../../../messages/dispatchMessage'

interface ILinksProps {
	element: Partial<IElement>
	setId: string | undefined | null
	resources: IResourcesMap
	thumbsUrl: string
	onSelect: (requiredOtherElementsIds?: Array<ElementId>) => void
}

const Links = (props: ILinksProps) => {
	const [image, setImage] = React.useState<string | null>(null)

	React.useEffect(() => {
		render()
	}, [props.resources, props.element.requiredOtherElementsIds])

	function render() {
		const defaultSet: ISet = Object.values(props.resources.set)[0]

		if (props.setId || defaultSet) {
			const drawables = DrawerController.testTemporanyElement(
				props.resources,
				props.setId || defaultSet.id,
				props.element
			)

			drawables
				.then(async prepared => {
					const blob = await Renderer.render(prepared, {
						width: 300,
						height: 300,
						watermark: false,
						scale: 0.65,
						translate: [0, 0.04],
					})
					blobToBase64(blob as Blob).then(image => {
						setImage(image)
					})
				})
				.catch(e => {
					dispatchMessage(e, { type: 'error' })
					console.trace(e)
				})
		}
	}

	function onSelect(elementId: ElementId) {
		const links = props.element.requiredOtherElementsIds || []

		if (!links.includes(elementId)) {
			props.onSelect([...links, elementId])
		}
	}

	function remove(elementId: ElementId) {
		const links = props.element.requiredOtherElementsIds || []

		if (links.includes(elementId)) {
			links.splice(links.indexOf(elementId), 1)
			props.onSelect([...links])
		}
	}

	return (
		<Flex gap="1rem">
			<div>
				<RawCanvas image={image} />
			</div>
			<Flex gap="1rem" direction="column">
				<div>
					Selected Links ({props.element.requiredOtherElementsIds ? props.element.requiredOtherElementsIds.length : 0}):
					<div>
						{(props.element.requiredOtherElementsIds || [])
							.filter(eId => eId)
							.map(elementId => (
								<div key={elementId}>
									<img
										src={props.thumbsUrl + '/' + props.resources.element[elementId].thumb}
										style={{ height: '2rem', display: 'inline-block', verticalAlign: 'middle' }}
										alt=""
									/>
									<div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
										{props.resources.element[elementId].name}
									</div>

									<RiCloseLine onClick={() => remove(elementId)} />
								</div>
							))}
					</div>
				</div>
				<div>
					<SelectHiddenElement resources={props.resources} thumbsUrl={props.thumbsUrl} onSelect={onSelect} />
				</div>
			</Flex>
		</Flex>
	)
}

const SelectHiddenElement = ({
	resources,
	thumbsUrl,
	onSelect,
}: {
	resources: IResourcesMap
	thumbsUrl: string
	onSelect: (e: ElementId) => void
}) => {
	const [hiddenCategory, setHiddenCategory] = React.useState<CategoryId | null>(null)

	function select(elementId: ElementId) {
		setHiddenCategory(null)
		onSelect(elementId)
	}
	return (
		<div>
			{!hiddenCategory ? (
				<ResourcePathSelector level={2} onlyHidden={true} onSelect={path => setHiddenCategory(path.category!.id)} />
			) : (
				<div>
					Category: <b>{resources.category[hiddenCategory].name}</b>{' '}
					<RiCloseLine onClick={() => setHiddenCategory(null)} />
					<div>
						{Object.values(
							resources.category[hiddenCategory].elements.map(elementId => resources.element[elementId])
						).map(element => (
							<div
								key={element.id}
								onClick={() => select(element.id)}
								style={{
									cursor: 'pointer',
									lineHeight: '2rem',
								}}
							>
								<img
									src={thumbsUrl + '/' + element.thumb}
									style={{ height: '2rem', display: 'inline-block', verticalAlign: 'middle' }}
									alt=""
								/>
								<div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{element.name}</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default connect((state: IRootState) => ({
	thumbsUrl: state.hosts.thumbs,
	resources: state.resources,
}))(Links)
