import * as React from 'react'

import { IPalette, IMapsOfPalette, IMixed } from '@faceyourmanga/fym-lib'
import { connect } from 'react-redux'

import { AiOutlinePlus } from 'react-icons/ai'

import { IRootState } from '../../../../types/store'
import Flex from '../../../components/Flex'
import SortableResource from '../../../components/SortableResource'
import ViewPalette from './ViewPalette'
import { PrivateApi } from '@faceyourmanga/fym-shared'

interface IPaletteProps {
	palette: IMapsOfPalette
}

const Palette = (props: IPaletteProps) => {
	const palettes = Object.values(props.palette).sort((a, b) => a.order - b.order)
	const [openPaletteId, setOpenPaletteId] = React.useState<string | null>(
		palettes && palettes.length > 0 ? palettes[0].id : null
	)

	React.useEffect(() => {
		if (openPaletteId) {
			if (typeof props.palette[openPaletteId] === 'undefined') {
				const palettes = Object.values(props.palette).sort((a, b) => a.order - b.order)
				setOpenPaletteId(palettes && palettes.length > 0 ? palettes[0].id : null)
			}
		}
	}, [openPaletteId, props.palette])

	return (
		<div className="palette">
			<div className="palette__content">
				<div className="palette__content__list">
					<SortableResource
						selectedId={openPaletteId || ''}
						list={Object.values(props.palette) as Array<IMixed>}
						type="palette"
					>
						{(p: IMixed) => <div onClick={() => setOpenPaletteId(p.id)}>{p.name}</div>}
					</SortableResource>
					<div>
						<CreateNewPalette onCreate={setOpenPaletteId} />
					</div>
				</div>
				<div className={'palette__content__view' + (openPaletteId ? ' palette__content__view--open' : '')}>
					{openPaletteId && props.palette[openPaletteId] && (
						<ViewPalette
							key={openPaletteId + '_' + Object.keys(props.palette[openPaletteId].colors).length}
							{...props.palette[openPaletteId]}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

function CreateNewPalette({ onCreate }: { onCreate: (paletteId: string) => void }) {
	const [name, setName] = React.useState<string>('')

	async function createPalette() {
		try {
			const palette = await PrivateApi.post<IPalette>('v1/resources/palette', { name, variations: 1, colors: {} })
			setName('')
			onCreate(palette.id)
		} catch (e: any) {}
	}

	return (
		<Flex gap="0.5rem" style={{ marginTop: '0.5rem', padding: '0 0.5rem' }}>
			<input type="text" placeholder="Crea..." value={name} onChange={e => setName(e.target.value)} />
			<div>
				<button disabled={name.length <= 0} onClick={createPalette}>
					<AiOutlinePlus className="icon" />
				</button>
			</div>
		</Flex>
	)
}

export default connect((state: IRootState) => ({
	palette: state.resources.palette,
}))(Palette)
