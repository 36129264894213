import * as React from 'react'
import { connect } from 'react-redux'

import { TAppMessages, IAppMessage, IRootState } from '../../types/store'

const Messages: React.FunctionComponent<{ messages: TAppMessages }> = ({ messages }) => {
	const messages_array: Array<IAppMessage> = Object.values(messages).reverse()

	return (
		<ul className="messages">
			{messages_array.map(message => (
				<li
					className={
						'messages__message' +
						(message.data && message.data.type
							? ' ' +
							  message.data.type
									.split(' ')
									.map((e: string) => 'messages__message--' + e)
									.join(' ')
							: '')
					}
					key={message.id}
					dangerouslySetInnerHTML={{ __html: message.message }}
				></li>
			))}
		</ul>
	)
}

export default connect((state: IRootState) => ({ messages: state.messages }))(Messages)
