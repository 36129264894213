import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { Grant } from '@faceyourmanga/fym-shared'
import { UserScopes } from '@faceyourmanga/fym-lib/dist'

interface IGrantRoute {
	path: string
	scopes: Array<UserScopes>
	Component: React.JSXElementConstructor<any>
}
function GrantRoute({ path, scopes, Component }: IGrantRoute) {
	// TODO: validate routes for user
	return (
		<Route
			path={path}
			component={() => <Grant scopes={scopes} Fallback={<Redirect to="/" />} Component={<Component />} />}
		/>
	)
}

export default GrantRoute
