import * as React from 'react'
import { ICategory } from '@faceyourmanga/fym-lib/src'

import { IResourceForm } from '../../../components/CreateUpdateResource'
import BaseForm from '../BaseForm'
import ResourcePathSelector from '../../../components/ResourcePath/ResourcePathSelector'
import { MdClose } from 'react-icons/md'
import Grid from '../../../components/Grid'
import Flex from '../../../components/Flex'
import CategoryFormTransformDependsOn from './CategoryFormTransformDependsOn'
import CategoryFormDefaultElementTransform from './CategoryFormDefaultElementTransform'
import CategoryFormPaletteDependsOtherCategory from './CategoryFormPaletteDependsOtherCategory'
import CategoryFormMappingCategoryToPalette from './CategoryFormMappingCategoryToPalette'
import CategoryFormDefaultElement from './CategoryFormDefaultElement'

const CategoryForm = (props: IResourceForm) => {
	return (
		<BaseForm {...props}>
			{(state, onChange) => {
				const category: ICategory = state

				if (typeof category.mappingPalette === 'undefined') category.mappingPalette = {}

				return (
					<React.Fragment>
						<Grid columns={2} gap="2rem" valign="top" flow="max-content auto">
							<div>
								<Grid columns={2} gap="0.5rem" flow="max-content auto">
									{category.group ? (
										<React.Fragment>
											<label>Fa parte del gruppo</label>
											<div>
												{props.resources.group[category.group].name}
												<MdClose onClick={() => onChange('group', undefined)} />
											</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<label>Seleziona Gruppo</label>
											<ResourcePathSelector
												level={1}
												onSelect={path => path.group && onChange('group', path.group.id)}
											/>
										</React.Fragment>
									)}

									<label>
										<b>DrawRequired: </b>
									</label>
									<input
										type="checkbox"
										checked={state.drawRequired}
										onChange={e => onChange('drawRequired', e.target.checked)}
									/>

									<label>zIndex:</label>
									<input
										type="number"
										value={state.zIndex}
										onChange={e => onChange('zIndex', parseInt(e.target.value))}
									/>

									<label>Default Element</label>
									<div>
										<CategoryFormDefaultElement category={category} onChange={onChange} resources={props.resources} />
									</div>
								</Grid>

								<hr />

								<CategoryFormTransformDependsOn category={category} onChange={onChange} resources={props.resources} />
							</div>

							<Flex direction="column" gap="0.5rem" style={{ marginTop: '0.5rem' }}>
								<CategoryFormDefaultElementTransform
									category={category}
									onChange={onChange}
									resources={props.resources}
								/>

								<div>
									<CategoryFormPaletteDependsOtherCategory
										category={category}
										onChange={onChange}
										resources={props.resources}
									/>

									{/* {typeof category.dependsCategoryPalette === 'undefined' && ( */}
									<CategoryFormMappingCategoryToPalette
										category={category}
										onChange={onChange}
										resources={props.resources}
									/>
									{/* )} */}
								</div>
							</Flex>
						</Grid>
					</React.Fragment>
				)
			}}
		</BaseForm>
	)
}

export default CategoryForm
