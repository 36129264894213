import { Store } from 'redux'

import { BaseProtocol, Message } from '@faceyourmanga/fym-lib'
import ProtoPing from './wsproto/ProtoPing'
import ProtoResources from './wsproto/ProtoResources'
import { Auth } from '@faceyourmanga/fym-shared'
import dispatchMessage from '../app/messages/dispatchMessage'

let socket: WebSocket | undefined

function bindWebsocket(wsEndpoint: string, store: Store): void {
	closeWebsocket()
	const token = Auth.getAuthenticationAccessToken().getAccessToken()

	const websocket = new WebSocket(wsEndpoint + '?bearerToken=' + token, 'protocolResources')

	// Put here protos with initialization
	const protos = [new ProtoPing(), new ProtoResources(store)]

	websocket.onopen = event => {
		dispatchMessage('Connected to websocket', undefined, 3000)
		protos.forEach(p => p.bind(websocket))
	}

	websocket.onmessage = event => {
		const incoming: Message<BaseProtocol> = JSON.parse(event.data)

		protos
			// @ts-ignore
			.filter(p => p.PROTO_ID === incoming.protocol && p.actions.includes(incoming.action))
			// @ts-ignore same as up
			.forEach(p => p.onAction(incoming.action, incoming.data))

		// if (
		// 	incoming.action === 'changed' &&
		// 	incoming.data &&
		// 	'name' in incoming.data &&
		// 	incoming.data['name'] === 'element'
		// ) {
		// 	console.log('removePrerenderElement')
		// 	Renderer.removePrerenderElement(incoming.data['id'])
		// }
	}

	websocket.onclose = event => {
		dispatchMessage('Websocket closed unexpectly. Reconnecting...')
		protos.forEach(p => p.unbind(websocket))
		bindWebsocket(wsEndpoint, store)
	}

	socket = websocket
}

function closeWebsocket() {
	if (socket) {
		socket.close()
	}
}

export { bindWebsocket, closeWebsocket }
