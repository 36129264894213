import React from 'react'
import { IServerPagination } from '@faceyourmanga/fym-shared'

interface IUsersPaginatorBarProps {
	paginator: IServerPagination<any, any>
	searchBy?: Array<string>
}

function UsersPaginatorBar(props: IUsersPaginatorBarProps) {
	const count = props.paginator.getCount()
	const page = props.paginator.getPage()

	return (
		<div className="paginatorBar">
			{props.searchBy && props.searchBy.length > 0 && (
				<div className="paginatorBar__search">
					search:
					<input
						type="search"
						value={props.paginator.getQuery().search}
						onChange={e => {
							props.paginator.query({ search: e.target.value })
						}}
					/>
					by
					<div className="paginatorBar__search__by">
						<select
							value={props.paginator.getQuery().searchBy}
							onChange={e => {
								props.paginator.query({ searchBy: e.target.value })
							}}
						>
							{props.searchBy.map(v => (
								<option value={v}>{v}</option>
							))}
						</select>
					</div>
				</div>
			)}
			<div className="paginatorBar__size">
				for page:
				<select
					value={props.paginator.getSize()}
					onChange={e => {
						props.paginator.setSize(parseInt(e.target.value))
					}}
				>
					<option value={5}>5</option>
					<option value={10}>10</option>
					<option value={25}>25</option>
					<option value={100}>100</option>
				</select>
			</div>
			<div className="paginatorBar__page">
				page:
				<select value={page} onChange={e => props.paginator.goTo(parseInt(e.target.value))}>
					{new Array(count).fill(1).map((v, i) => (
						<option value={i + 1}>{i + 1}</option>
					))}
				</select>
			</div>
		</div>
	)
}

export default React.memo(UsersPaginatorBar)
