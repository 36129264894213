export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'

interface ShowMessageAction {
	type: typeof SHOW_MESSAGE
	message: string
	data?: any
	message_id: number
}

interface HideMessageAction {
	type: typeof HIDE_MESSAGE
	message_id: number
}

export type MessagesActionTypes = ShowMessageAction | HideMessageAction
