import { TStoreResource } from '../../types/store'

import { ResourcesActionTypes, IMPORT_RESOURCES_STATE, ADD_RESOURCE, REMOVE_RESOURCE, UPDATE_RESOURCE } from './types'

export const initialState: TStoreResource = {
	transform: {},
	palette: {},
	set: {},
	group: {},
	category: {},
	element: {},
}

export function resourcesReducer(state = initialState, action: ResourcesActionTypes): TStoreResource {
	switch (action.type) {
		case IMPORT_RESOURCES_STATE:
			return { ...state, ...action.resources }

		case ADD_RESOURCE: {
			const newresource = { ...state[action.item_type] }
			newresource[action.item.id] = action.item as any
			return { ...state, [action.item_type]: { ...newresource } }
		}

		case REMOVE_RESOURCE: {
			const newresource = { ...state[action.item_type] }
			delete newresource[action.item.id]
			return { ...state, [action.item_type]: { ...newresource } }
		}

		case UPDATE_RESOURCE: {
			const newresource = { ...state[action.item_type] }
			// @ts-ignore
			newresource[action.item.id] = action.item
			return { ...state, [action.item_type]: { ...newresource } }
		}

		default:
			return state
	}
}
