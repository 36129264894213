import { CategoryId, SetId } from '@faceyourmanga/fym-lib/dist'
import store from '../../redux/root'
import { IPath } from '../../types/resources'

export const createFromSetId = (setId: string): IPath => {
	const resources = store.getState().resources
	return {
		set: resources.set[setId],
	}
}

export const getSetIdFromCategoryId = (categoryId: CategoryId): SetId => {
	const resources = store.getState().resources

	return resources.group[resources.category[categoryId].group].set
}
