import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import store from './redux/root'
import App from './app/App'

import { AuthVerified } from '@faceyourmanga/fym-shared'
import Loading from './Loading'
import Login from './auth/Login'

const Boot: React.FunctionComponent<{}> = () => {
	const baseTags = document.getElementsByTagName('base')
	const base: string = (baseTags && baseTags.length ? baseTags[0].href : document.URL).replace(
		window.location.origin,
		''
	)

	return (
		<Provider store={store}>
			<AuthVerified Loading={<Loading />}>
				<BrowserRouter basename={base}>
					<Switch>
						<Route exact path="/login" component={Login} />
						<Route path="/" component={App} />
					</Switch>
				</BrowserRouter>
			</AuthVerified>
		</Provider>
	)
}

export default Boot
