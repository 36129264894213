import * as React from 'react'
import PupsColor from '@pups/core/build/Models/Color/Color'

interface IColorProps {
	color: string
	selected?: boolean
	withLabel?: boolean
	size?: number | string
}

const Color: React.FunctionComponent<IColorProps & any> = ({ withLabel, color, selected, size, ...otherProps }) => {
	size = typeof size === 'string' ? size : undefined

	// const p = new PupsColor(color)

	if (!color || color.length <= 0) color = 'rgba(255,255,255,.1)'

	return (
		<div
			className={`color ${selected ? 'color--selected' : ''} ${withLabel ? 'color--withLabel' : ''} `}
			// className={`color ${selected ? 'color--selected' : ''} ${withLabel ? 'color--withLabel' : ''} ${
			// 	// p.isDark ? 'color--dark' : 'color--light'
			// 	'color--light'
			// }`}
			style={{
				width: size,
				height: size,
				cursor: 'onClick' in otherProps ? 'pointer' : undefined,
				background: color,
			}}
			{...otherProps}
		>
			{withLabel ? color : null}
		</div>
	)
}

export default React.memo(Color)
