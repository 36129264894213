import {
	ICreateUser,
	IPublicUser,
	Nationalities,
	nationalities,
	passedGDPRDate,
	UserScopes,
	userScopes,
} from '@faceyourmanga/fym-lib'
import { PromiseButton } from '@faceyourmanga/fym-shared'
import { PrivateApi } from '@faceyourmanga/fym-shared'
import React from 'react'
import SelectDate from '../../components/SelectDate'
import SelectNationality from '../../components/SelectNationality'
import dispatchMessage from '../../messages/dispatchMessage'

interface ICreateUserProps {
	onCreate: () => void
}

function CreateUser(props: ICreateUserProps) {
	const [user, setUser] = React.useState<ICreateUser>({
		birth: new Date(),
		nationality: 'Italy',
		scopes: [],
		email: '',
		password: '',
	})

	function toggleScope(scope: UserScopes) {
		const scopes = [...user.scopes]
		if (user.scopes.includes(scope)) {
			scopes.splice(scopes.indexOf(scope), 1)
		} else {
			scopes.push(scope)
		}

		setUser({ ...user, scopes })
	}

	async function save() {
		if (passedGDPRDate(user.birth) && nationalities.includes(user.nationality as Nationalities)) {
			try {
				await PrivateApi.post<IPublicUser>('auth/create', { ...user, birth: user.birth.getTime() })

				props.onCreate()
			} catch (e: any) {
				dispatchMessage(e.data.message, {
					type: 'error',
				})
			}
		} else {
			dispatchMessage('La data di nascita o la nazionalità non sono conformi alle regole del GDPR', {
				type: 'error',
			})
		}
	}

	return (
		<div className="users__create">
			<h1>CreateUser</h1>

			<div>
				Email: <input type="email" value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
			</div>

			<div>
				Password:{' '}
				<input type="password" value={user.password} onChange={e => setUser({ ...user, password: e.target.value })} />
			</div>

			<div>
				Nickname:{' '}
				<input type="text" value={user.nickname} onChange={e => setUser({ ...user, nickname: e.target.value })} />
			</div>

			<div>
				scopes:
				<div className="users__create__scopes">
					{userScopes.map(scope => (
						<div key={scope}>
							{scope}{' '}
							<input type="checkbox" checked={user.scopes.includes(scope)} onChange={() => toggleScope(scope)} />
						</div>
					))}
				</div>
			</div>
			<div>
				birthdate <small>(year-month-day)</small>
				<SelectDate
					value={user.birth.toISOString().substr(0, 10)}
					onChange={birth => setUser({ ...user, birth: new Date(Date.parse(birth)) })}
				/>
			</div>

			<div>
				nationality:
				<SelectNationality value={user.nationality} onChange={nationality => setUser({ ...user, nationality })} />
			</div>
			<PromiseButton onClick={save}>Save</PromiseButton>
		</div>
	)
}

export default React.memo(CreateUser)
