function getenv(field: keyof Window['env']): string {
	const value = process?.env[field] ?? window?.env[field]

	if (value === undefined) {
		throw new Error(`Missing env variable [${field}]`)
	}

	return value
}

if (typeof process === 'object') {
	global.getenv = getenv
} else {
	window.getenv = getenv
}

export {}
