import React from 'react'
import { Auth, IServerPagination } from '@faceyourmanga/fym-shared'
import { IPublicTransaction, transactionStatus } from '@faceyourmanga/fym-lib'
import { PrivateApi } from '@faceyourmanga/fym-shared/src'
import { createDownload } from '../../../utilities/createDownload'

const MONTHS = [
	'Gennaio',
	'Febbraio',
	'Marzo',
	'Aprile',
	'Maggio',
	'Giugno',
	'Luglio',
	'Agosto',
	'Settembre',
	'Ottobre',
	'Novembre',
	'Dicembre',
]
function dateToHuman(date: number): string {
	const parsed = new Date(date)

	return `${parsed.getDate()} ${MONTHS[parsed.getMonth()]} ${parsed.getFullYear()}`
}

function priceToHuman(price: number | undefined, currency: string): string {
	if (price) {
		return price.toFixed(2) + ' ' + currency
	}
	return '-'
}

function Payment({ payment, paginator }: { payment: IPublicTransaction; paginator: IServerPagination<any, any> }) {
	async function downloadInvoice() {
		const headers: any = {
			Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
		}

		const response = await fetch(getenv('REACT_APP_SERVER_URL') + `v1/transaction/${payment.id}/invoice`, {
			method: 'GET',
			headers,
		})

		createDownload(`invoice-${payment.id}`, await response.blob(), 'application/pdf')
	}

	async function refund() {
		const headers: any = {
			Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
			'Content-Type': 'application/json',
		}

		const response = await fetch(getenv('REACT_APP_SERVER_URL') + `v1/switch-refund`, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				transactionId: payment.id,
				status: 'refunded',
			}),
		})

		paginator.reload()
	}

	async function complete() {
		const headers: any = {
			Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
			'Content-Type': 'application/json',
		}

		const response = await fetch(getenv('REACT_APP_SERVER_URL') + `v1/switch-refund`, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				transactionId: payment.id,
				status: 'completed',
			}),
		})

		paginator.reload()
	}

	return (
		<div className="payments__list__item">
			<div>
				<label title={payment.id}>
					{payment.id.substr(0, 3)}...{payment.id.substr(-3)}
				</label>
			</div>
			<div>{payment.gateway}</div>
			<div>{dateToHuman(payment.created_at)}</div>
			<div>{payment.status}</div>
			<div>{payment.country}</div>
			<div>{priceToHuman(payment.realPrice, payment.currency)}</div>
			<div>{priceToHuman(payment.totalPrice, payment.currency)}</div>
			<div>
				{payment.vatPercentage}% <small>{payment.vat ? priceToHuman(payment.vat, payment.currency) : ''}</small>
			</div>
			<div>
				<span onClick={downloadInvoice}>download</span>
			</div>
			<div>
				{payment.status === 'completed' ? (
					<button onClick={refund}>Refund</button>
				) : payment.status === 'refunded' ? (
					<button onClick={complete}>Complete</button>
				) : null}
			</div>
		</div>
	)
}

export default React.memo(Payment)
