import { CategoryId, ICategory, IResources } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import ResourcePathSelector from '../../../components/ResourcePath/ResourcePathSelector'

interface CategoryFormPaletteDependsOtherCategoryProps {
	resources: IResources
	category: Partial<ICategory>
	onChange: (name: string, value: any) => void
}

function CategoryFormPaletteDependsOtherCategory({
	category,
	resources,
	onChange,
}: CategoryFormPaletteDependsOtherCategoryProps) {
	function addCategoryDependencyPalette(
		dependsCategoryPaletteId: CategoryId | undefined,
		onChange: (name: string, value: any) => void
	) {
		console.log('dependsCategoryPaletteId', dependsCategoryPaletteId, category)
		if (typeof dependsCategoryPaletteId !== 'undefined') {
			onChange('dependsCategoryPalette', dependsCategoryPaletteId)
		} else {
			onChange('dependsCategoryPalette', undefined)
		}
	}

	return (
		<div>
			Categoria su cui devono dipendere i colori:
			{category.dependsCategoryPalette ? (
				<div>
					{resources.category[category.dependsCategoryPalette].name} -
					<span onClick={() => addCategoryDependencyPalette(undefined, onChange)}>remove</span>
				</div>
			) : (
				<ResourcePathSelector
					level={2}
					onSelect={path => path.category && addCategoryDependencyPalette(path.category.id, onChange)}
				/>
			)}
		</div>
	)
}

export default React.memo(CategoryFormPaletteDependsOtherCategory)
