import { IResources, ICategory } from '@faceyourmanga/fym-lib/dist'
import React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../../types'
import { joinUrl } from '@faceyourmanga/fym-shared'
interface ICategoryFormDefaultElement {
	thumbsPath: string
	resources: IResources
	category: Partial<ICategory>
	onChange: (name: string, value: any) => void
}

function CategoryFormDefaultElement(props: ICategoryFormDefaultElement) {
	if (!props.category.elements || props.category.elements.length <= 0) {
		return <div>nessun elemento collegato</div>
	}

	const elements = props.category.elements.map(eId => props.resources.element[eId]).filter(e => e)

	return (
		<div style={{ maxHeight: '6rem', overflow: 'auto' }}>
			{elements.map(e => (
				<div
					key={e.id}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '0.5rem',
						border: `1px solid ${props.category.defaultElement === e.id ? 'rgba(255,255,255,.4)' : 'transparent'}`,
					}}
					onClick={() => props.onChange('defaultElement', e.id)}
				>
					<img alt="" style={{ width: '2rem', height: '2rem' }} src={joinUrl(props.thumbsPath, e.thumb)} />

					{e.name}
				</div>
			))}
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		thumbsPath: state.hosts.thumbs,
	}))(CategoryFormDefaultElement)
)
