import * as React from 'react'
interface Props {
	gap?: number | string
	halign?: string
	valign?: string
	direction?: 'column' | 'row'
	style?: any
	component?: string
	className?: string
	onClick?: () => void
}

const Flex: React.FunctionComponent<Props> = ({
	gap,
	halign,
	direction,
	valign,
	style,
	children,
	component,
	className,
	...otherProps
}) => {
	const _style = {
		display: 'flex',
		gap: gap,
		flexDirection: direction,
		alignItems: valign,
		justifyContent: halign,
		...style,
	}

	return React.createElement(component || 'div', { style: _style, className, ...otherProps }, children)
}

export default Flex
