import * as React from 'react'
import { connect } from 'react-redux'

import {
	ApplyTransform,
	CategoryId,
	IElement,
	IRange,
	IResourcesMap,
	ISet,
	ITransform,
	TransformId,
} from '@faceyourmanga/fym-lib'
import SelectOrCreateResource from '../../../components/ResourcePath/SelectOrCreateResource'
import { IRootState } from '../../../../types/store'
import Flex from '../../../components/Flex'
import { DrawerController } from '@faceyourmanga/fym-draw'
import { Renderer } from '@faceyourmanga/fym-draw'
import RawCanvas from '../../../components/RawCanvas'
import dispatchMessage from '../../../messages/dispatchMessage'
import ViewTransform from '../transform/ViewTransform'
import { MdClose } from 'react-icons/md'
import { blobToBase64 } from '../../../../extension/blob'

interface ITransformProps {
	element: Partial<IElement>
	setId: string | undefined | null
	selected: string | null | undefined
	transform: Record<string, ITransform>
	resources: IResourcesMap
	onSelect: (transform: ITransform | undefined) => void
}

const Transforms = (props: ITransformProps) => {
	const [transform, setTransform] = React.useState<ITransform | undefined>(
		props.selected ? props.transform[props.selected] : undefined
	)
	const [image, setImage] = React.useState<string | null>(null)
	const [applyTransform, setApplyTransform] = React.useState<ApplyTransform>({
		...ApplyTransform.default(),
		scaleIsZoom: transform ? transform.scaleIsZoom : ApplyTransform.default().scaleIsZoom,
	})

	React.useEffect(() => {
		setTransform(props.selected ? props.transform[props.selected] : undefined)
	}, [props.resources.transform, props.selected])

	const inheritedTransform = (props.element.categories || [])
		.map(categoryId => ({
			categoryId,
			transformId: props.resources.category[categoryId].transform,
		}))
		.filter(c => typeof c.transformId !== 'undefined') as Array<{ categoryId: CategoryId; transformId: TransformId }>

	function render() {
		const defaultSet: ISet = Object.values(props.resources.set)[0]

		if (props.setId || defaultSet) {
			const drawables = DrawerController.testTemporanyElement(
				props.resources,
				props.setId || defaultSet.id,
				props.element,
				transform,
				applyTransform
			)

			drawables
				.then(async prepared => {
					const blob = await Renderer.render(prepared, {
						width: 300,
						height: 300,
						watermark: false,
						scale: 0.65,
						translate: [0, 0.04],
					})
					blobToBase64(blob as Blob).then(image => {
						setImage(image)
					})
				})
				.catch(e => {
					dispatchMessage(e, { type: 'error' })
					console.trace(e)
				})
		}
	}

	React.useEffect(() => {
		setTransform(props.selected ? props.transform[props.selected] : undefined)
	}, [props.selected])

	React.useEffect(() => {
		if (props.resources && transform) {
			// setInitted(true)
			render()
		}
	}, [applyTransform, transform, props.resources])

	const getTransformProp = (name: keyof ApplyTransform) => {
		if (transform && transform[name as keyof ITransform]) {
			const range = transform[name as keyof ITransform] as IRange
			return (
				<div>
					{name}
					<input
						onChange={e =>
							setApplyTransform({
								...applyTransform,
								[name]: parseFloat(e.target.value),
								scaleIsZoom: transform.scaleIsZoom,
							})
						}
						type="range"
						value={applyTransform[name] as number}
						min={range.min}
						max={range.max}
						step={range.step}
					/>
					{applyTransform[name]}
				</div>
			)
		}
		return null
	}

	return (
		<Flex gap="1rem">
			<div>
				<RawCanvas image={image} />
			</div>
			<div>
				{inheritedTransform.length > 0 && (
					<div>
						Se non setterai nessuna transform verranno prese quelle di default per la categorie:
						{inheritedTransform.map(c => (
							<div key={c.categoryId} onClick={() => setTransform(props.resources.transform[c.transformId])}>
								{props.resources.category[c.categoryId].name}: {props.resources.transform[c.transformId].name}
							</div>
						))}
					</div>
				)}

				<h3>Setta e testa una Transform</h3>

				<Flex gap="1rem" direction="column">
					<div>
						{transform ? (
							<div>
								<div style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>
									<b>{transform.name}</b>
									<span
										onClick={() => {
											props.onSelect(undefined)
											setTransform(undefined)
											setApplyTransform(ApplyTransform.default())
										}}
									>
										<MdClose />
									</span>
								</div>
								{transform.scaleIsZoom ? (
									<React.Fragment>{getTransformProp('scaleX')}</React.Fragment>
								) : (
									<React.Fragment>
										{getTransformProp('scaleX')}
										{getTransformProp('scaleY')}
									</React.Fragment>
								)}
								{getTransformProp('translateX')}
								{getTransformProp('translateY')}
								{getTransformProp('rotateZ')}
								{getTransformProp('opacity')}
								{/* extend? */}
							</div>
						) : (
							<SelectOrCreateResource
								type="transform"
								resources={Object.values(props.transform)}
								onSelect={o => {
									props.onSelect(o as ITransform)
								}}
							/>
						)}
					</div>
					<div>
						{transform && (
							<div>
								Modifica rapida:
								<div style={{ fontSize: '.8rem' }}>
									<ViewTransform {...(transform as ITransform)} />
								</div>
							</div>
						)}
					</div>
				</Flex>
			</div>
		</Flex>
	)
}

export default connect((state: IRootState) => ({
	transform: state.resources.transform,
	resources: state.resources,
}))(Transforms)
