import * as React from 'react'

import Flex from '../../../components/Flex'
import { ITransform } from '@faceyourmanga/fym-lib/dist'
import { PrivateApi } from '@faceyourmanga/fym-shared'
import ConfirmPromise from '../../../components/Confirm'
import dispatchMessage from '../../../messages/dispatchMessage'
import Range from '../../../forms/Range'
import { IRange } from '@faceyourmanga/fym-lib/src'

const ViewTransform = (props: ITransform) => {
	const [transform, setTransform] = React.useState<ITransform>(props)

	React.useEffect(() => {
		setTransform(props)
	}, [props])

	async function save() {
		try {
			await PrivateApi.put<ITransform>('v1/resources/transform/' + props.id, transform)
			dispatchMessage(`Salvataggio completato`, { type: 'success' })
		} catch (e: any) {
			dispatchMessage(`Errore nel salvataggio della transform ${transform.name}`, { type: 'error' })
		}
	}

	async function deleteTransform() {
		if (await ConfirmPromise(`Cancellare la transform '${props.name}'`)) {
			try {
				PrivateApi.delete('v1/resources/transform/' + props.id)

				dispatchMessage(`${props.name} cancellata`, { type: 'success' })
			} catch (e: any) {
				dispatchMessage(`<div>Errore cancellazione "${props.name}"</div><div>${e.message}</div>`)
			}
		}
	}

	return (
		<Flex direction="column" gap="1rem">
			<Flex gap="1rem" style={{ marginTop: '2rem' }}>
				<span>Nome</span>
				<input
					type="text"
					value={transform.name}
					onChange={e => setTransform({ ...transform, name: e.target.value })}
				/>
			</Flex>
			<RangeProp
				name="translateX"
				transform={transform}
				defaultValue={{ min: -10, max: 10, step: 1 }}
				onChange={setTransform}
			/>
			<RangeProp
				name="translateY"
				transform={transform}
				defaultValue={{ min: -10, max: 10, step: 1 }}
				onChange={setTransform}
			/>
			<Flex gap="0.5rem">
				scaleIsZoom:
				<input
					type="checkbox"
					checked={transform.scaleIsZoom}
					onChange={e => setTransform({ ...transform, scaleIsZoom: e.target.checked })}
				/>
			</Flex>
			{transform.scaleIsZoom ? (
				<RangeProp
					name="scaleX"
					label="scale"
					transform={transform}
					defaultValue={{ min: 0.9, max: 1.1, step: 0.1 }}
					onChange={setTransform}
				/>
			) : (
				<React.Fragment>
					<RangeProp
						name="scaleX"
						transform={transform}
						defaultValue={{ min: 0.9, max: 1.1, step: 0.1 }}
						onChange={setTransform}
					/>
					<RangeProp
						name="scaleY"
						transform={transform}
						defaultValue={{ min: 0.9, max: 1.1, step: 0.1 }}
						onChange={setTransform}
					/>
				</React.Fragment>
			)}
			<RangeProp
				name="rotateZ"
				transform={transform}
				defaultValue={{ min: -45, max: 45, step: 1 }}
				onChange={setTransform}
			/>
			<RangeProp
				name="opacity"
				transform={transform}
				defaultValue={{ min: 0, max: 1, step: 0.2 }}
				onChange={setTransform}
			/>
			<div>
				<button className="viewPalette__save" onClick={save}>
					Salva
				</button>
			</div>
			<button className="viewPalette__delete" onClick={deleteTransform}>
				Elimina
			</button>
		</Flex>
	)
}

function RangeProp({
	name,
	label,
	transform,
	defaultValue,
	onChange,
}: {
	name: keyof ITransform
	label?: string
	transform: ITransform
	defaultValue: IRange
	onChange: (transform: ITransform) => void
}) {
	return (
		<Flex gap="0.5rem">
			{label || name}
			<input
				type="checkbox"
				checked={typeof transform[name] !== 'undefined'}
				onChange={e => onChange({ ...transform, [name]: e.target.checked ? defaultValue : undefined })}
			/>
			{typeof transform[name] !== 'undefined' && (
				<Range value={transform[name] as IRange | undefined} onChange={r => onChange({ ...transform, [name]: r })} />
			)}
		</Flex>
	)
}

export default ViewTransform
