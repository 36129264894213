import { IBaseResource } from '@faceyourmanga/fym-lib'
import { PromiseButton } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { IResourceForm } from '../../components/CreateUpdateResource'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'

import Thumb from '../Thumb'

const BaseForm = (props: IResourceForm & { children?: TBaseFormChild }) => {
	const [state, setState] = React.useState<Partial<IBaseResource>>(props.value || {})

	const onChange = (name: string, value: any) => {
		setState({ ...state, [name]: value })
	}

	return (
		<React.Fragment>
			<Grid columns={2} gap="0.5rem" flow="max-content auto" style={{ maxWidth: '30rem' }}>
				<label>Nome</label>
				<input type="text" value={state.name} onChange={e => onChange('name', e.target.value)} />
				{/* {props.mode === 'update' && (
					<React.Fragment>
						<label>Order</label>
						<input type="number" value={state.order} onChange={e => onChange('order', parseInt(e.target.value))} />
					</React.Fragment>
				)} */}
				<label>
					<b>Visibile?</b>
				</label>
				<input type="checkbox" checked={state.visible} onChange={e => onChange('visible', e.target.checked)} />
				<label>Miniatura</label> <Thumb value={state.thumb || ''} onChange={e => onChange('thumb', e)} />
			</Grid>

			<div style={{ margin: '1rem 0' }}>{props.children && props.children(state, onChange)}</div>

			<Flex halign="space-between">
				<PromiseButton onClick={() => props.onSubmit(state)}>
					{props.mode === 'update' ? 'Modifica' : 'Crea'}
				</PromiseButton>

				{props.mode === 'update' && <button onClick={() => props.onRemove()}>Rimuovi</button>}
			</Flex>

			{props.errors && (
				<ul>
					{props.errors.map((e, i) => (
						<li key={i}>{e}</li>
					))}
				</ul>
			)}
		</React.Fragment>
	)
}

export type TBaseFormChild = (state: any, onChange: (name: string, value: any) => void) => JSX.Element

export default BaseForm
