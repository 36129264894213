import * as React from 'react'
import { connect } from 'react-redux'

import ResourcePathSelector from '../../../components/ResourcePath/ResourcePathSelector'
import { IPath } from '../../../../types/resources'
import ShowResourcePath from '../../../components/ResourcePath/ShowResourcePath'
import { IRootState } from '../../../../types/store'
import { IResources } from '@faceyourmanga/fym-lib/dist'

import { MdClose } from 'react-icons/md'

interface ICategoriesPathProps {
	onSelect: (categoriesId: Array<string>) => void
	selecteds: Array<string>
	resources: IResources
}

const CategoriesPath = (props: ICategoriesPathProps) => {
	const [paths, setPaths] = React.useState<Array<IPath>>([])

	React.useEffect(() => {
		const paths: Array<IPath> = props.selecteds
			.map(category_id => {
				const category = props.resources.category[category_id]
				const group = category ? props.resources.group[category.group] : undefined
				const set = group ? props.resources.set[group.set] : undefined

				return category && group && set ? { category, group, set } : undefined
			})
			.filter(e => !!e) as Array<IPath>

		setPaths(paths)
	}, [props])

	function removePath(index: number) {
		const new_paths = [...paths]
		new_paths.splice(index, 1)

		props.onSelect(new_paths.map(p => p.category?.id) as Array<string>)
	}

	function addPath(path: IPath) {
		const new_category = path.category?.id
		if (typeof new_category === 'string' && !props.selecteds.includes(new_category)) {
			props.onSelect([...props.selecteds, new_category])
		}
	}

	return (
		<React.Fragment>
			<div style={{ marginTop: '1rem' }}>Seleziona le categorie di appartenenza partendo dal set</div>
			<div style={{ marginBottom: '1rem' }}>Non puoi selezionare due categorie che appartengono allo stesso set</div>
			<ResourcePathSelector level={2} onSelect={addPath} enableCreation />

			{paths.length > 0 && <div>Path selezionate: </div>}
			<ul className="create-element__slide__meta__categories__selecteds">
				{paths.map((path, index) => (
					<li key={index} className="create-element__slide__meta__categories__selecteds__item">
						<MdClose onClick={() => removePath(index)} />
						<ShowResourcePath path={path} />
					</li>
				))}
			</ul>
		</React.Fragment>
	)
}

export default connect((state: IRootState) => ({
	resources: state.resources,
}))(CategoriesPath)
