import store from '../../redux/root'
import { hideMessage, showMessage } from '../../redux/messages/actions'

export default function (message: string, data?: any, durate = 3000): void {
	const { message_id } = store.dispatch(showMessage(message, data))

	setTimeout(() => {
		store.dispatch(hideMessage(message_id))
	}, durate)
}
