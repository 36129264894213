import { ICategory, IGroup, ISet, IResources } from '@faceyourmanga/fym-lib/dist'
import * as React from 'react'
import { connect } from 'react-redux'
import { IPath } from '../../../types/resources'
import { IRootState } from '../../../types/store'
import SelectOrCreateResource from './SelectOrCreateResource'

interface IResourcePathSelectorProps {
	resources: IResources
	level?: 0 | 1 | 2 // 0 set, 1 group, 3 category
	enableCreation?: boolean
	onSelect?: (path: IPath) => void | boolean
	path?: IPath
	onlyHidden?: boolean
}

const ResourcePathSelector: React.FunctionComponent<IResourcePathSelectorProps> = ({
	level = 0,
	resources,
	enableCreation = false,
	onlyHidden = false,
	onSelect,
	path,
}) => {
	const [state, setState] = React.useState<IPath | undefined>(path)

	React.useEffect(() => {
		function isValidPath(): boolean {
			switch (level) {
				case 0:
					return state !== undefined && state.set !== undefined
				case 1:
					return state !== undefined && state.set !== undefined && state.group !== undefined
				case 2:
					return (
						state !== undefined && state.set !== undefined && state.group !== undefined && state.category !== undefined
					)
			}
		}

		if (isValidPath()) {
			onSelect && onSelect(state as IPath)
			setState({})
		}
	}, [state, level, onSelect])

	const components = []

	if (state === undefined || state.set === undefined) {
		components.push(
			<SelectOrCreateResource
				key="select-set"
				type="set"
				enableCreation
				resources={Object.values(resources.set)}
				onSelect={set => setState({ ...state, set: set as ISet })}
			/>
		)
	} else {
		components.push(
			<div key="set-name" onClick={() => setState({})}>
				{state.set.name}
			</div>
		)

		if (state.group === undefined) {
			components.push(
				<SelectOrCreateResource
					key="select-group"
					type="group"
					enableCreation
					resources={state.set.groups
						.map(group_id => resources.group[group_id])
						.filter(e => (onlyHidden ? e.visible === false : true))}
					createInitialData={{ set: state.set.id }}
					onSelect={group => setState({ ...state, group: group as IGroup })}
					onBack={() => setState({})}
				/>
			)
		} else {
			components.push(
				<div key="group-name" onClick={() => setState({ set: state.set })}>
					{state.group.name}
				</div>
			)
			if (state.category === undefined) {
				components.push(
					<SelectOrCreateResource
						key="select-category"
						type="category"
						enableCreation
						resources={state.group.categories
							.map(category_id => resources.category[category_id])
							.filter(e => (onlyHidden ? e.visible === false : true))}
						createInitialData={{ group: state.group.id }}
						onSelect={category => setState({ ...state, category: category as ICategory })}
						onBack={() => setState({ set: state.set })}
					/>
				)
			} else {
				components.push(
					<div key="category-name" onClick={() => setState({ ...state, category: undefined })}>
						{state.category.name}
					</div>
				)
			}
		}
	}

	return (
		<div className="resource-path-selector">
			<div className="resource-path-selector__path">{components}</div>
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		resources: state.resources,
	}))(ResourcePathSelector)
)
