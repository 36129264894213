import * as React from 'react'

import { AiOutlineWarning } from 'react-icons/ai'

import { PrivateApi } from '@faceyourmanga/fym-shared'
import { IPalette } from '@faceyourmanga/fym-lib'
import { SVG } from '@faceyourmanga/fym-draw/dist'
import { ColorEntry, Colors } from '@faceyourmanga/fym-lib'

import dispatchMessage from '../../../messages/dispatchMessage'
import Flex from '../../../components/Flex'
import AlertPromise from '../../../components/Alert'
import ConfirmPromise from '../../../components/Confirm'

import SinglePaletteForm from '../../../forms/resources/SinglePaletteForm'
import PaletteColors from './PaletteColors'

const ViewPalette = (props: IPalette) => {
	const [name, setName] = React.useState<string>(props.name)
	const [variations, setVariations] = React.useState<number>(props.variations)

	const [newColorEntry, setNewColorEntry] = React.useState<Partial<ColorEntry> | null>(null)

	async function saveName() {
		const state: IPalette = { ...props, variations }

		state.name = name

		save(state)
	}

	async function saveVariations() {
		if (
			await ConfirmPromise(
				'Con questa modifica potranno succedere degli imprevisti sulle palette, Continuare?',
				'Si',
				'Annulla'
			)
		) {
			const state: IPalette = { ...props, variations }

			state.colors = Object.values(state.colors)
				.map((p: ColorEntry) => ({
					...p,
					values: new Array(variations).fill('#ffffff').map((e, i) => (p && p.values[i] ? p.values[i] : e)),
				}))
				.reduce((acc, val) => {
					acc[val.colorId] = val
					return acc
				}, {} as Colors)

			save(state)
		}
	}

	async function addPalette(color: ColorEntry) {
		if (color && color.values.filter(c => c && SVG.isHEX(c)).length > 0) {
			const state = { ...props }
			color.order = Object.keys(state.colors).length
			state.colors[color.colorId] = color

			try {
				await save(state)
				setNewColorEntry(null)
			} catch (e: any) {
				AlertPromise('È successo un errore')
			}
		} else {
			AlertPromise('Completare la palette')
		}
	}

	function updatePalette(colorEntryId: string, color: ColorEntry) {
		const state = { ...props }

		state.colors[colorEntryId] = color

		save(state)
	}

	async function save(updated: IPalette) {
		try {
			await PrivateApi.put<IPalette>('v1/resources/palette/' + props.id, updated)
			dispatchMessage(`Salvataggio completato`, { type: 'success' })
		} catch (e: any) {
			dispatchMessage(`Errore nel salvataggio della palette ${updated.name}`, { type: 'error' })
		}
	}

	async function deletePalette() {
		if (await ConfirmPromise(`Cancellare la palette '${props.name}'`)) {
			try {
				await PrivateApi.delete('v1/resources/palette/' + props.id)

				dispatchMessage(`${props.name} cancellata`, { type: 'success' })
			} catch (e: any) {
				console.log([e])
				const message = e.data?.message || e.message
				dispatchMessage(`<div>Errore cancellazione "${props.name}"</div><div>${message}</div>`, { type: 'error' })
			}
		}
	}

	async function deletePaletteColor(colorEntry: ColorEntry) {
		if (typeof props.colors[colorEntry.colorId] !== 'undefined') {
			if (await ConfirmPromise(`Continuare?`)) {
				const updated = { ...props }
				delete updated.colors[colorEntry.colorId]
				save(updated)
			}
		}
	}

	return (
		<div>
			<Flex gap="1rem" style={{ marginTop: '2rem' }}>
				<span>Nome</span>
				<input type="text" value={name} onChange={e => setName(e.target.value)} />
				{name !== props.name && <button onClick={saveName}>Salva</button>}
			</Flex>
			<div style={{ marginTop: '1rem' }}>
				<Flex gap="0.5rem">
					<span>Variazioni</span>
					<input type="number" min="1" value={variations} onChange={e => setVariations(parseInt(e.target.value))} />

					{variations !== props.variations && (
						<button onClick={saveVariations}>
							<Flex valign="center" gap="0.2rem">
								<AiOutlineWarning className="icon" /> <span>Cambia il numbero di variazioni</span>
							</Flex>
						</button>
					)}
				</Flex>
			</div>
			<div>
				<h4>Lista delle Palette</h4>

				<PaletteColors
					palette={props}
					deletePaletteColor={deletePaletteColor}
					save={save}
					updatePalette={updatePalette}
				/>
			</div>
			<div style={{ marginTop: '2rem' }}>
				{newColorEntry ? (
					<Flex gap="1rem" valign="center">
						<span>Nuova palette</span>
						<SinglePaletteForm
							value={newColorEntry as ColorEntry}
							variations={props.variations}
							onChange={addPalette}
						/>
					</Flex>
				) : (
					<button onClick={() => setNewColorEntry({})}>Aggiungi palette</button>
				)}
			</div>
			<button className="viewPalette__delete" onClick={deletePalette}>
				Elimina
			</button>
		</div>
	)
}

export default ViewPalette
