import { Renderer, DrawerController, ClientSVGSetting } from '@faceyourmanga/fym-draw'
import { CategoryId, ElementId, IMask, IResourcesMap } from '@faceyourmanga/fym-lib'
import { IElement } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { MdClose } from 'react-icons/md'
import Flex from '../../../components/Flex'
import RawCanvas from '../../../components/RawCanvas'
import ResourcePathSelector from '../../../components/ResourcePath/ResourcePathSelector'
import ShowResourcePath from '../../../components/ResourcePath/ShowResourcePath'
import UploadSVG from '../../../components/UploadSVG'
import dispatchMessage from '../../../messages/dispatchMessage'
import { IPath } from '../../../../types/resources'
import { blobToBase64 } from '../../../../extension/blob'

interface IMaskPreviewProps {
	mask: IMask
	element: Partial<IElement>
	setId: string | undefined | null
	removeMaskPath: () => void
	addPathFromSVG: (svg: string) => void
	categoryIdToIPath: (categoryId: string) => IPath
	addMasking: (path: IPath) => void
	removeCategory: (categoryIndex: number) => void
	remove: () => void
	resources: IResourcesMap
}

function MaskPreview(props: IMaskPreviewProps) {
	const [tempTestELementMask, setTempTestELementMask] = React.useState<Record<CategoryId, ElementId>>({})
	const [image, setImage] = React.useState<string | null>(null)

	React.useEffect(() => {
		if (props.resources && props.resources.set && props.mask) {
			draw()
		}
	}, [props, tempTestELementMask])

	function draw() {
		const setId = props.setId || Object.values(props.resources.set)[0].id

		const drawables = DrawerController.testTemporanyElement(
			props.resources,
			setId,
			props.element,
			undefined,
			undefined,
			props.mask.masking,
			tempTestELementMask
		)

		drawables
			.then(async prepared => {
				const blob = await Renderer.render(prepared, {
					width: 300,
					height: 300,
					watermark: false,
					scale: 0.65,
					translate: [0, 0.04],
				})
				blobToBase64(blob as Blob).then(image => {
					setImage(image)
				})
			})
			.catch(e => {
				dispatchMessage('Draw-error: ' + e, { type: 'error' })
			})
	}

	function setTempCategoryMaskElement(categoryId: CategoryId, elementId: ElementId) {
		setTempTestELementMask({ ...tempTestELementMask, [categoryId]: elementId })
	}

	function downloadMask() {
		const template = ClientSVGSetting.svgTemplate

		const mask = template
			.replace(ClientSVGSetting.trackPath, '')
			.replace('</svg>', `<path d="${props.mask.path}" /></svg>`)

		const blob = new Blob([mask], {
			type: 'image/svg+xml;charset=utf-8',
		})

		const a = document.createElement('a')

		a.target = '_blank'
		a.download = 'FaceYourManga ' + props.element.name + '_mask'
		a.href = URL.createObjectURL(blob)

		document.body.appendChild(a)
		a.click()
		a.remove()
	}

	return (
		<Flex gap="1rem">
			<div style={{ flex: 1 }}>
				<div style={{ marginBottom: '0.5rem' }}>Aggiungi categoria a cui applicala: </div>
				<ResourcePathSelector level={2} onSelect={path => props.addMasking(path)} />

				<div style={{ margin: '1rem 0' }}>
					{props.mask.masking.length > 0 && (
						<div style={{ margin: '1rem 0' }}>
							<div>Categorie su cui si applicherà la maschera</div>
							{props.mask.masking.map((categoryId, categoryIndex) => (
								<div key={categoryId} style={{ marginTop: '0.5rem' }}>
									<ShowResourcePath path={props.categoryIdToIPath(categoryId)} />
									<MdClose onClick={() => props.removeCategory(categoryIndex)} />
									<div>
										<div
											style={{
												display: 'inline-block',
												margin: '0.25rem 0',
												maxHeight: '4rem',
												overflow: 'auto',
												fontSize: '.8rem',
												padding: '0.25rem',
												background: 'rgba(255,255,255,.2)',
											}}
										>
											{Object.values(
												props.resources.category[categoryId].elements.map(
													elementId => props.resources.element[elementId]
												)
											).map(element => (
												<div
													key={element.id}
													onClick={() => setTempCategoryMaskElement(categoryId, element.id)}
													style={{
														cursor: 'pointer',
														textDecoration: tempTestELementMask[categoryId] === element.id ? 'underline' : '',
													}}
												>
													{element.name}
												</div>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
					)}

					{props.mask.path && props.mask.path.length > 0 ? (
						<div>
							Hai selezionato la maschera
							<MdClose onClick={props.removeMaskPath} />
							<button onClick={downloadMask}>Scarica maschera</button>
						</div>
					) : (
						<UploadSVG setSVG={svg => props.addPathFromSVG(svg)} />
					)}
				</div>

				<button onClick={props.remove}>Rimuovi la maschera</button>
			</div>
			<div style={{ flex: 1 }}>
				<RawCanvas image={image} />
			</div>
		</Flex>
	)
}

export default React.memo(MaskPreview)
