import { IMixed, IResources } from '@faceyourmanga/fym-lib/dist'
import * as React from 'react'
import CreateUpdateResource from '../CreateUpdateResource'
import Modal from '../Modal'

interface ISelectOrCreateResourceProps {
	type: keyof IResources
	resources: Array<IMixed>
	createInitialData?: Partial<IMixed>
	enableCreation?: boolean
	onSelect: (resource: IMixed) => void
	onBack?: () => void
}

const SelectOrCreateResource: React.FunctionComponent<ISelectOrCreateResourceProps> = ({
	resources,
	createInitialData,
	enableCreation = false,
	type,
	onSelect,
	onBack,
}) => {
	const [name, setName] = React.useState<string>('')
	const [bModalOpen, setModalOpen] = React.useState<{ state: boolean; data: any }>({ state: false, data: {} })

	const options = resources.filter(resource => resource.name.toLowerCase().indexOf(name.toLowerCase()) >= 0)

	function onEnter() {
		const result = resources.filter(resource => resource.name.toLowerCase() === name.toLowerCase())
		if (result.length === 1) {
			onSelect(result[0])
		} else if (enableCreation) {
			setModalOpen({
				state: true,
				data: { name },
			})
		}
	}

	function onTab(e: React.KeyboardEvent) {
		e.preventDefault()

		if (options.length === 1) onSelect(options[0])
	}

	function onBackspace() {
		if (name.length === 0) {
			onBack && onBack()
			return true
		}

		return false
	}

	return (
		<React.Fragment>
			<div className="select-resource">
				<input
					placeholder={`select ${type}`}
					className="select-resource__input"
					value={name}
					style={{ width: Math.max(8, name.length / 2.15 + 1) + 'rem' }}
					onKeyDown={e =>
						(e.key === 'Backspace' && onBackspace()) ||
						(e.key === 'Enter' && onEnter()) ||
						(e.key === 'Tab' && onTab(e))
					}
					onChange={e => setName(e.target.value)}
				/>

				<ul className={`select-resource__options ${name.length > 0 ? 'select-resource__options--open' : ''}`}>
					{options.length === 0 && <li className="select-resource__options__empty">no {type} found</li>}
					{options.map(resource => (
						<li key={resource.id} className="select-resource__options__option" onClick={() => onSelect(resource)}>
							{resource.name}
						</li>
					))}
				</ul>
			</div>

			{enableCreation && (
				<Modal
					bCloseOnBackground={true}
					open={bModalOpen.state}
					close={() => setModalOpen({ state: false, data: bModalOpen.data })}
				>
					<CreateUpdateResource
						type={type}
						initialData={{ ...createInitialData, ...bModalOpen.data }}
						onSuccess={new_resource => onSelect(new_resource)}
					/>
				</Modal>
			)}
		</React.Fragment>
	)
}

export default React.memo(SelectOrCreateResource)
