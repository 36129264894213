import React from 'react'

function myUseRef<T>(initial: any = null): any {
	// eslint-disable-next-line
	const ref = React.useRef<T>(initial)
	// eslint-disable-next-line
	const setRef = React.useCallback(next => {
		if (ref.current) {
			// Make sure to cleanup any events/references added to the last instance
		}
		if (next) {
			// Check if a node is actually passed. Otherwise node would be null.
			// You can now do what you need to, addEventListeners, measure, etc.
		}
		// Save a reference to the node
		ref.current = next
	}, [])
	return [ref, setRef]
}

export default myUseRef
