import * as React from 'react'
import { IElement } from '@faceyourmanga/fym-lib/dist'
import Modal from '../../../components/Modal'
import Element from '../element/Element'

interface ICreatorElementProps {
	newElement: Partial<IElement> | null
	setNewElement: (element: Partial<IElement> | null) => void
}

function CreatorElement(props: ICreatorElementProps) {
	return (
		<Modal open={props.newElement !== null} close={() => props.setNewElement(null)}>
			<Element close={() => props.setNewElement(null)} element={props.newElement} />
		</Modal>
	)
}

export default React.memo(CreatorElement)
