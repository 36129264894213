import { ClientSVGSetting } from '@faceyourmanga/fym-draw/dist'
import * as React from 'react'

interface RawCanvasProps {
	image?: string | null
	width?: number
	height?: number
}

const RawCanvas = (props: RawCanvasProps) => {
	const canvasRef = React.useRef<HTMLCanvasElement>(null)

	React.useEffect(() => {
		requestAnimationFrame(() => {
			if (canvasRef && canvasRef.current && props.image) {
				const canvas = canvasRef.current
				const context = canvas.getContext('2d', { alpha: true })

				if (context) {
					const image = new Image()
					image.onload = () => {
						context.drawImage(image, 0, 0)
					}
					image.src = props.image
				}
			}
		})
	}, [props.image, canvasRef])

	React.useEffect(() => {
		if (canvasRef && canvasRef.current) {
			const canvas = canvasRef.current
			canvas.width = ClientSVGSetting.renderPreviewSize[0]
			canvas.height = ClientSVGSetting.renderPreviewSize[1]
			canvas.style.width = (props.width || 400) + 'px'
			canvas.style.height = (props.height || 400) + 'px'
		}
	}, [canvasRef])

	return <canvas style={{ position: 'sticky', top: '0' }} ref={canvasRef}></canvas>
}

export default RawCanvas
