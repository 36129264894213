import * as React from 'react'
import { connect } from 'react-redux'
import { ReactSortable } from 'react-sortablejs'
import { IExistentBaseResource, IRootState } from '../../types/store'
import { updateResources } from '../../redux/resources/actions'
import { Api, PrivateApi } from '@faceyourmanga/fym-shared'

import { IMixed, IResources } from '@faceyourmanga/fym-lib/dist'

interface ISortableResourceProps {
	className?: string
	selectedId?: string
	type: keyof IResources
	list: Array<IExistentBaseResource | IMixed>
	updateResources?: (items: Array<IExistentBaseResource>) => void
	children: (item: IMixed) => JSX.Element
}

function SortableResource(props: ISortableResourceProps) {
	const [list, setList] = React.useState<Array<IExistentBaseResource>>([])

	function onEnd() {
		if (props.updateResources) {
			const promises: Array<Promise<IExistentBaseResource>> = []

			for (let i = 0, len = list.length; i < len; i++) {
				list[i].order = i
				promises.push(PrivateApi.put<IExistentBaseResource>(`v1/resources/${props.type}/${list[i].id}`, list[i]))
			}

			Promise.all(promises).then(items => props.updateResources && props.updateResources(items))
		}
	}

	React.useEffect(() => {
		props.list &&
			setList(
				(Object.values(props.list).filter(e => !!e) as Array<IExistentBaseResource>).sort((a, b) => a.order - b.order)
			)
	}, [props.list])

	return (
		<ReactSortable
			animation={200}
			className="sortable"
			list={list}
			setList={setList}
			onEnd={onEnd}
			handle=".sortable__item__handle"
		>
			{(list as Array<IMixed>).map((item: IMixed) => (
				<div
					key={item.id}
					className={`sortable__item ${props.className || ''} ${
						item.id === props.selectedId ? 'sortable__item--selected' : ''
					}`}
				>
					<div className="sortable__item__handle"></div>
					<div className="sortable__item__content">{props.children(item)}</div>
				</div>
			))}
		</ReactSortable>
	)
}

export default connect(
	(state: IRootState, props: ISortableResourceProps) => ({
		typedResource: state.resources[props.type],
	}),
	(dispatch, props: ISortableResourceProps) => ({
		updateResources: (items: Array<IExistentBaseResource>) =>
			dispatch(updateResources(props.type, items as Array<IMixed>)),
	})
)(SortableResource)
