import { IPublicUser, UserPagination } from '@faceyourmanga/fym-lib'

import { useServerPaginator } from '@faceyourmanga/fym-shared'

import * as React from 'react'
import Modal from '../../components/Modal'

import CreateUser from './CreateUser'
import User from './User'
import UsersPaginatorBar from './UsersPaginatorBar'

function Users() {
	const [createUserModal, setCreateUserModal] = React.useState(false)

	const paginator = useServerPaginator<IPublicUser, UserPagination>('v1/users', {
		email: true,
		facebook: true,
		google: true,
		order: 'ASC',
		page: 1,
		search: '',
		searchBy: 'username',
		size: parseInt(localStorage.getItem('be-users-size') || '10'),
		sortBy: 'id',
	})

	localStorage.setItem('be-users-size', paginator.getSize() + '')

	return (
		<section className="users">
			<div className="users__head">
				<div
					className="users__head__item users__head__item--cliccable"
					onClick={() => {
						paginator.query({ sortBy: 'id', order: paginator.getQuery().order === 'ASC' ? 'DESC' : 'ASC' })
					}}
				>
					id
				</div>
				<div className="users__head__item">Nickname</div>
				<div
					className="users__head__item users__head__item--cliccable"
					onClick={() => {
						paginator.query({ sortBy: 'username', order: paginator.getQuery().order === 'ASC' ? 'DESC' : 'ASC' })
					}}
				>
					Username
				</div>
				<div className="users__head__item">Banned</div>
				<div className="users__head__item">Scopes</div>
			</div>

			<div className="users__list">
				{paginator.getItems().map(user => (
					<User key={user.id} user={user} paginator={paginator} />
				))}
			</div>

			<div className="users__filters"></div>

			<UsersPaginatorBar paginator={paginator} searchBy={['username', 'nickname']} />

			<button onClick={() => setCreateUserModal(true)}>create user</button>

			<Modal
				open={createUserModal}
				close={() => setCreateUserModal(false)}
				bCloseOnBackground={false}
				bCloseOnEsc={true}
			>
				<CreateUser
					onCreate={() => {
						setCreateUserModal(false)
						paginator.reload()
					}}
				/>
			</Modal>
		</section>
	)
}

export default React.memo(Users)
