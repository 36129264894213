import * as React from 'react'

interface IExpandableProps {
	status: boolean
	mode?: 'display' | undefined
}
const Openable: React.FunctionComponent<IExpandableProps> = props => {
	if (props.mode === 'display') {
		return <div style={{ display: props.status ? '' : 'nonde' }}>{props.children}</div>
	}
	return props.status ? (props.children as JSX.Element) : null
}

function OpenableHook(defaultState: boolean = false): any {
	const [state, setState] = React.useState<boolean>(defaultState)

	function toggle() {
		setState(!state)
	}

	return [state, toggle, setState]
}

export { Openable, OpenableHook }
