import { CurrencyCode, IPublicTransaction, TransactionPagination } from '@faceyourmanga/fym-lib'
import { Auth, PromiseButton, useServerPaginator } from '@faceyourmanga/fym-shared'
import JSZip from 'jszip'
import * as React from 'react'
import { createDownload } from '../../../utilities/createDownload'
import Payment from './Payment'
import PaymentsPaginatorBar from './PaymentsPaginatorBar'

function Payments() {
	const [filters, setFilters] = React.useState<TransactionPagination>({
		page: 1,
		size: parseInt(localStorage.getItem('be-payments-size') || '10'),
		order: 'DESC',
		orderBy: 'date',
	})

	const paginator = useServerPaginator<IPublicTransaction, TransactionPagination>('v1/transactions', filters)

	localStorage.setItem('be-payments-size', paginator.getSize() + '')

	async function downloadAll() {
		const items = paginator.getItems()
		const promises: Array<Promise<{ blob: Blob; transaction: IPublicTransaction }>> = []
		const zip = new JSZip()

		const headers: any = {
			Authorization: 'Bearer ' + Auth.getAuthenticationAccessToken().getAccessToken(),
		}

		items.forEach(async transaction => {
			promises.push(
				new Promise(resolve => {
					fetch(getenv('REACT_APP_SERVER_URL') + `v1/transaction/${transaction.id}/invoice`, {
						method: 'GET',
						headers,
					}).then(response => {
						response.blob().then(blob => {
							resolve({ blob, transaction })
						})
					})
				})
			)
		})

		Promise.all(promises).then(results => {
			results.forEach((result, index) => {
				const filename = index + 1 + '__' + result.transaction.id + '.pdf'
				zip.file(filename, result.blob)
			})

			zip.generateAsync({ type: 'blob' }).then(function (content) {
				createDownload('download.zip', content, 'application/zip')
			})
		})
	}

	const currencies: Record<CurrencyCode, { totalPrice: number; totalVat: number }> = paginator
		.getItems()
		.filter(transaction => transaction.status === 'completed')
		.map(transaction => {
			return {
				currency: transaction.currency,
				price: transaction.totalPrice!,
				vat: transaction.vat!,
			}
		})
		.reduce((acc, t) => {
			if (!acc[t.currency]) {
				acc[t.currency] = {
					totalPrice: 0,
					totalVat: 0,
				}
			}

			acc[t.currency].totalPrice += t.price
			acc[t.currency].totalVat += t.vat

			return acc
		}, {} as Record<CurrencyCode, { totalPrice: number; totalVat: number }>)

	return (
		<section className="payments">
			<div className="payments__head">
				<div className="payments__head__item payments__head__item--cliccable">id</div>
				<div className="payments__head__item">Gateway</div>
				<div
					className="payments__head__item"
					onClick={() => {
						paginator.query({ orderBy: 'date', order: paginator.getQuery().order === 'ASC' ? 'DESC' : 'ASC' })
					}}
				>
					Date
				</div>
				<div className="payments__head__item">Status</div>
				<div
					className="payments__head__item payments__head__item--cliccable"
					onClick={() => {
						paginator.query({ orderBy: 'nationality', order: paginator.getQuery().order === 'ASC' ? 'DESC' : 'ASC' })
					}}
				>
					Country
				</div>
				<div
					className="payments__head__item payments__head__item--cliccable"
					onClick={() => {
						paginator.query({ orderBy: 'price', order: paginator.getQuery().order === 'ASC' ? 'DESC' : 'ASC' })
					}}
				>
					Total
				</div>
				<div className="payments__head__item payments__head__item--cliccable">Total + IVA</div>
				<div className="payments__head__item payments__head__item--cliccable">IVA</div>
				<div className="payments__head__item"></div>
			</div>

			<div className="payments__list">
				{paginator.getItems().map(payment => (
					<Payment key={payment.id} payment={payment} paginator={paginator} />
				))}
			</div>

			<PaymentsPaginatorBar paginator={paginator} />

			<div className="payments__footer">
				<div>
					<PromiseButton onClick={downloadAll}>Download all</PromiseButton>
				</div>

				<div>
					{Object.keys(currencies).map(currency => (
						<div key={currency} className="payments__footer__total">
							<b>{currency}</b>
							<span>totalPrice: {currencies[currency as CurrencyCode].totalPrice.toFixed(2)}</span>
							<span>totalVat: {currencies[currency as CurrencyCode].totalVat.toFixed(2)}</span>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default React.memo(Payments)
