export function createDownload(name: string, data: any, mime: string): void {
	const blob = new Blob([data], { type: mime })
	const url = URL.createObjectURL(blob)
	const a = document.createElement('a')

	a.setAttribute('href', url)
	a.setAttribute('download', name)
	a.setAttribute('target', '_blank')

	document.body.appendChild(a)

	a.click()
	URL.revokeObjectURL(url)
	a.remove()
}
