import { IElement } from '@faceyourmanga/fym-lib/dist'
import * as React from 'react'
import CategoriesPath from './CategoriesPath'

interface IElementMetaProps {
	element: Partial<IElement>
	setElement: (element: Partial<IElement>) => void
}

const ElementMeta = ({ element, setElement }: IElementMetaProps) => {
	return (
		<div className="create-element__slide__meta">
			<div>
				<input
					className="create-element__slide__meta__input"
					type="text"
					placeholder="Inserisci il nome"
					value={element.name}
					onChange={e => setElement({ ...element, name: e.target.value })}
				/>
				<div>
					<b>Visible ?</b>
					<input
						type="checkbox"
						checked={element.visible}
						onChange={e => setElement({ ...element, visible: e.target.checked })}
					/>
				</div>
				<div>
					<label>zIndex:</label>
					<input
						type="number"
						value={element.zIndex}
						onChange={e => setElement({ ...element, zIndex: parseInt(e.target.value) })}  
					/>
				</div>
				<div className="create-element__slide__meta__categories">
					<CategoriesPath
						selecteds={element.categories as Array<string>}
						onSelect={(categoriesIds: Array<string>) => setElement({ ...element, categories: categoriesIds })}
					/>
				</div>
				
			</div>
		</div>
	)
}

export default ElementMeta
