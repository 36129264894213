import { ICategory, IResources, MappingCategoryToPalette, MappingPalette } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { MdClose } from 'react-icons/md'
import { ReactSortable } from 'react-sortablejs'
import SelectOrCreateResource from '../../../components/ResourcePath/SelectOrCreateResource'

interface CategoryFormMappingCategoryToPaletteProps {
	resources: IResources
	category: Partial<ICategory>
	onChange: (name: string, value: any) => void
}

type MappingCategoryToPaletteListItem = MappingCategoryToPalette & { id: string; mappingPaletteId: string }

function CategoryFormMappingCategoryToPalette({
	category,
	resources,
	onChange,
}: CategoryFormMappingCategoryToPaletteProps) {
	// resources.palette[mp.palette].name

	const mappings = category.mappingPalette || {}

	function mappingsToList(mappings: MappingPalette) {
		return Object.entries(mappings).map(([mappingPaletteId, l]) => ({ ...l, id: l.name + l.palette, mappingPaletteId }))
	}

	const [list, setList] = React.useState<Array<MappingCategoryToPaletteListItem>>(mappingsToList(mappings))

	function findKeyByValue(item: MappingCategoryToPalette): string | undefined {
		return Object.keys(mappings).find(key => mappings[key].name === item.name && mappings[key].palette === item.palette)
	}

	function onEnd() {
		const mappings = category.mappingPalette || {}

		for (let i = 0, len = list.length; i < len; i++) {
			const mappingKey: string | undefined = findKeyByValue(list[i])
			if (mappingKey && mappings[mappingKey]) {
				mappings[mappingKey].order = i
			}
		}

		onChange('mappingPalette', mappings)
	}

	React.useEffect(() => {
		category.mappingPalette &&
			setList(
				Object.values(mappingsToList(category.mappingPalette))
					.filter(e => !!e)
					.sort((a, b) => a.order - b.order)
			)
	}, [category.mappingPalette])

	return (
		<div>
			<div>
				<div>E/O mappa le palette con la categoria</div>
				<ReactSortable
					animation={200}
					className="sortable"
					list={list}
					setList={setList}
					onEnd={onEnd}
					handle=".sortable__item__handle"
				>
					{(list as Array<MappingCategoryToPaletteListItem>).map((item: MappingCategoryToPaletteListItem) => (
						<div key={item.id} className="sortable__item" style={{ display: 'flex' }}>
							<div
								className="sortable__item__handle"
								style={{
									width: '0.5rem',
									height: '2rem',
									cursor: 'move',
									background: 'rgba(255, 255, 255, 0.4)',
								}}
							></div>
							<div className="sortable__item__content">
								<ShowUpdateMapping
									key={item.id}
									mappingPaletteId={item.mappingPaletteId}
									mappingCategoryToPalette={item}
									resources={resources}
									category={category}
									onChange={onChange}
								/>
							</div>
						</div>
					))}
				</ReactSortable>
			</div>

			<CreateNewMapping resources={resources} category={category} onChange={onChange} />
		</div>
	)
}

function ShowUpdateMapping({
	resources,
	category,
	onChange,
	mappingPaletteId,
	mappingCategoryToPalette,
}: CategoryFormMappingCategoryToPaletteProps & {
	mappingPaletteId: string
	mappingCategoryToPalette: MappingCategoryToPalette
}) {
	const [updateMappingCategoryToPalette, setUpdateMappingCategoryToPalette] =
		React.useState<Partial<MappingCategoryToPalette> | null>(null)

	if (updateMappingCategoryToPalette !== null) {
		return (
			<div>
				<input
					placeholder="Nome (es. Pupille)"
					type="text"
					value={updateMappingCategoryToPalette.name || ''}
					onChange={e => setUpdateMappingCategoryToPalette({ ...updateMappingCategoryToPalette, name: e.target.value })}
				/>
				{updateMappingCategoryToPalette.palette ? (
					<span
						onClick={() => setUpdateMappingCategoryToPalette({ ...updateMappingCategoryToPalette, palette: undefined })}
					>
						remove {resources.palette[updateMappingCategoryToPalette.palette].name}
					</span>
				) : (
					<SelectOrCreateResource
						type="palette"
						resources={Object.values(resources.palette)}
						onSelect={palette => {
							setUpdateMappingCategoryToPalette({ ...updateMappingCategoryToPalette, palette: palette.id })
						}}
					/>
				)}
				{updateMappingCategoryToPalette.name && updateMappingCategoryToPalette.palette && (
					<button
						onClick={() => {
							onChange('mappingPalette', {
								...category.mappingPalette,
								[mappingPaletteId]: {
									...updateMappingCategoryToPalette,
									order: updateMappingCategoryToPalette.order || 0,
								},
							})
							setUpdateMappingCategoryToPalette(null)
						}}
					>
						Update
					</button>
				)}

				<button onClick={() => setUpdateMappingCategoryToPalette(null)}>Annulla</button>
			</div>
		)
	}

	return (
		<div>
			<MdClose
				onClick={() => {
					const updated = { ...category.mappingPalette }
					delete updated[mappingPaletteId]
					onChange('mappingPalette', updated)
				}}
			/>
			{mappingCategoryToPalette.name} -&gt; {resources.palette[mappingCategoryToPalette.palette].name}-{' '}
			<span onClick={() => setUpdateMappingCategoryToPalette(mappingCategoryToPalette)}>update</span>
		</div>
	)
}

function CreateNewMapping({ resources, category, onChange }: CategoryFormMappingCategoryToPaletteProps) {
	const [newMappingCategoryToPalette, setNewMappingCategoryToPalette] = React.useState<
		Partial<MappingCategoryToPalette>
	>({})

	return (
		<div>
			<input
				placeholder="Nome (es. Pupille)"
				type="text"
				value={newMappingCategoryToPalette.name || ''}
				onChange={e => setNewMappingCategoryToPalette({ ...newMappingCategoryToPalette, name: e.target.value })}
			/>
			{newMappingCategoryToPalette.palette ? (
				<span onClick={() => setNewMappingCategoryToPalette({ ...newMappingCategoryToPalette, palette: undefined })}>
					remove {resources.palette[newMappingCategoryToPalette.palette].name}
				</span>
			) : (
				<SelectOrCreateResource
					type="palette"
					resources={Object.values(resources.palette)}
					onSelect={palette => {
						setNewMappingCategoryToPalette({ ...newMappingCategoryToPalette, palette: palette.id })
					}}
				/>
			)}
			{newMappingCategoryToPalette.name && newMappingCategoryToPalette.palette && (
				<button
					onClick={() => {
						onChange('mappingPalette', {
							...category.mappingPalette,
							[Math.random().toString(36).slice(2)]: { ...newMappingCategoryToPalette, order: 0 },
						})
						setNewMappingCategoryToPalette({})
					}}
				>
					Add
				</button>
			)}
		</div>
	)
}

export default React.memo(CategoryFormMappingCategoryToPalette)
