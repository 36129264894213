import { Auth, authReducer, hostsReducer, bindHosts } from '@faceyourmanga/fym-shared/dist'

import { resourcesReducer } from './resources/reducers'

import { combineReducers, createStore } from 'redux'
import { messageReducer } from './messages/reducers'

const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()

const rootReducer = combineReducers({
	auth: authReducer,
	hosts: hostsReducer,
	resources: resourcesReducer,
	messages: messageReducer,
})

const store = createStore(rootReducer, composeEnhancers)

Auth.updated(() => {
	store.dispatch({ type: 'AUTH_UPDATE' })
})

bindHosts(store)

export default store
