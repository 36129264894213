import { ClientSVGSetting, SVG } from '@faceyourmanga/fym-draw'
import * as React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../types'
import Dropzone from '../components/Dropzone'
import usePasteSVG from '../hooks/usePasteSVG'
import { joinUrl } from '@faceyourmanga/fym-shared'
import { blobToBase64 } from '../../extension/blob'

interface IThumbProps {
	thumbsPath: string
	value: string | null
	onChange: (value: string | null) => void
}

const Thumb = (props: IThumbProps) => {
	const [initialValue, setInitialValue] = React.useState<string | null>(null)

	const [thumb, setThumb] = React.useState<string | null>(
		props.value === 'default' || !props.value ? null : joinUrl(props.thumbsPath, props.value)
	)

	const svgData = usePasteSVG()

	React.useEffect(() => {
		if (svgData && svgData.raw) {
			setThumb(null)
			props.onChange(null)
			setTimeout(() => {
				console.log('SVG.toBase64Image', SVG)
				SVG.toBase64Image(svgData.raw, ClientSVGSetting.thumbSize[0], ClientSVGSetting.thumbSize[1]).then(blob => {
					console.log('asdasd', svgData.raw, blob)
					blobToBase64(blob as Blob).then(image => {
						console.log('asdasd2', image)
						setThumb(image)
						props.onChange(image)
					})
				})
			})
		}
	}, [svgData])

	React.useEffect(() => {
		setInitialValue(props.value)
	}, [])

	function onSelectImage(d: string) {
		setThumb(d)
		props.onChange(d)
	}

	function remove() {
		setThumb(null)
		props.onChange(null)
	}

	function restore() {
		if (initialValue !== 'default') {
			setThumb(joinUrl(props.thumbsPath, initialValue || ''))
			props.onChange(initialValue)
		}
	}

	return thumb ? (
		<div>
			<img className="create-update-resource-form__thumb" src={thumb} alt="" />
			<div onClick={remove}>rimuovi</div>
		</div>
	) : (
		<div>
			<Dropzone
				accept={['image/png', 'image/jpg', 'image/jpeg', 'image/webp']}
				onChange={onSelectImage}
				readType="dataUrl"
			/>

			{initialValue !== 'default' && initialValue && <div onClick={restore}>ripristina</div>}
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		thumbsPath: state.hosts.thumbs,
	}))(Thumb)
)
