import * as React from 'react'
import { MdClose } from 'react-icons/md'

import { IResourceForm } from '../../components/CreateUpdateResource'
import Grid from '../../components/Grid'
import ResourcePathSelector from '../../components/ResourcePath/ResourcePathSelector'
import BaseForm from './BaseForm'

const GroupForm = (props: IResourceForm) => {
	return (
		<BaseForm {...props}>
			{(state, onChange) => (
				<Grid columns={2} gap="0.5rem" flow="max-content auto">
					{state.set ? (
						<React.Fragment>
							<label>Fa parte del set</label>
							<div>
								<b>{props.resources.set[state.set].name}</b>
								<MdClose onClick={() => onChange('set', undefined)} />
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<label>Seleziona il set di appartenenza</label>
							<ResourcePathSelector level={0} onSelect={path => path.set && onChange('set', path.set.id)} />
						</React.Fragment>
					)}
				</Grid>
			)}
		</BaseForm>
	)
}

export default GroupForm
