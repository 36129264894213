import * as React from 'react'
import Modal from '../../../components/Modal'
import Transform from '../transform/Transform'

interface ICreatorTransformProps {
	open: boolean
	setOpen: (status: boolean) => void
}

function CreatorTransform(props: ICreatorTransformProps) {
	return (
		<Modal nopadding open={props.open} close={() => props.setOpen(false)}>
			<Transform />
		</Modal>
	)
}

export default React.memo(CreatorTransform)
