import { IMapsOfTransform } from '@faceyourmanga/fym-lib/dist'
import { ITransform } from '@faceyourmanga/fym-lib/src'
import * as React from 'react'
import { connect } from 'react-redux'

import { AiOutlinePlus } from 'react-icons/ai'
import { IRootState } from '../../../../types/store'
import Flex from '../../../components/Flex'
import ViewTransform from './ViewTransform'
import { PrivateApi } from '@faceyourmanga/fym-shared'
interface ITransformProps {
	transform: IMapsOfTransform
}

const Transform = (props: ITransformProps) => {
	const transform = Object.values(props.transform)
	const [openTransformId, setOpenTransformId] = React.useState<string | null>(transform[0] ? transform[0].id : null)

	React.useEffect(() => {
		if (openTransformId) {
			if (typeof props.transform[openTransformId] === 'undefined') {
				const transform = Object.values(props.transform)
				setOpenTransformId(transform[0] ? transform[0].id : null)
			}
		}
	}, [openTransformId, props.transform])

	return (
		<div className="palette">
			<div className="palette__content">
				<div className="palette__content">
					<div className="palette__content__list">
						<div className="sortable">
							{Object.values(props.transform).map(p => (
								<div
									key={p.id}
									className={'sortable__item' + (openTransformId === p.id ? ' sortable__item--selected' : '')}
									onClick={() => setOpenTransformId(p.id)}
								>
									<div></div>
									<div>{p.name}</div>
								</div>
							))}
						</div>
						<div>
							<CreateNewTransform onCreate={setOpenTransformId} />
						</div>
					</div>
				</div>
				<div className={'palette__content__view' + (openTransformId ? ' palette__content__view--open' : '')}>
					{openTransformId && props.transform[openTransformId] && (
						<ViewTransform key={openTransformId} {...props.transform[openTransformId]} />
					)}
				</div>
			</div>
		</div>
	)
}

function CreateNewTransform({ onCreate }: { onCreate: (transformId: string) => void }) {
	const [name, setName] = React.useState<string>('')

	async function createPalette() {
		try {
			const palette = await PrivateApi.post<ITransform>('v1/resources/transform', {
				name,
				scaleIsZoom: false,
			} as ITransform)
			setName('')
			onCreate(palette.id)
		} catch (e: any) {}
	}

	return (
		<Flex gap="0.5rem" style={{ marginTop: '0.5rem', padding: '0 0.5rem' }}>
			<input type="text" placeholder="Crea..." value={name} onChange={e => setName(e.target.value)} />
			<div>
				<button disabled={name.length <= 0} onClick={createPalette}>
					<AiOutlinePlus className="icon" />
				</button>
			</div>
		</Flex>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		transform: state.resources.transform,
	}))(Transform)
)
