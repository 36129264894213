import * as React from 'react'
import {
	ICategory,
	IPalette,
	MappingCategoryPaletteColors,
	PaletteId,
	ColorEntry,
	CategoryId,
	IGroup,
	ISet,
} from '@faceyourmanga/fym-lib'
import Flex from '../../../components/Flex'
import Color from '../../../components/Color'
import StaticPaletteView from '../../../components/StaticPaletteView'
import { connect } from 'react-redux'
import { IRootState } from '../../../../types'

interface MappingCategoryPaletteColorsCategoryProps {
	elementMappingCategoryPaletteColors: MappingCategoryPaletteColors | undefined
	category: ICategory
	realcategory: ICategory
	palette: Record<PaletteId, IPalette>
	svgColors: Array<string>
	groups: Record<string, IGroup>
	sets: Record<string, ISet>
	onHighlight: (color: string | undefined) => void
	onDeHighlight: () => void
	resetMappingForCategory: () => void
	addMappingCategoryPaletteColors: (
		categoryId: CategoryId,
		mappingPaletteId: string,
		paletteId: PaletteId,
		color: string | undefined
	) => void
}

function MappingCategoryPaletteColorsCategory(props: MappingCategoryPaletteColorsCategoryProps) {
	const possibleColorsForCategory = [...props.svgColors]

	if (typeof props.elementMappingCategoryPaletteColors !== 'undefined') {
		const mappingsToCategory = Object.values(
			props.category ? props.elementMappingCategoryPaletteColors[props.category.id] || {} : {}
		)

		const usedColors = Array.prototype.concat
			.apply([], mappingsToCategory)
			.filter(e => typeof e === 'string') as Array<string>

		for (let i = 0; i < usedColors.length; i++) {
			const find = possibleColorsForCategory.indexOf(usedColors[i])
			if (find >= 0) {
				possibleColorsForCategory.splice(find, 1)
			}
		}
	}

	const group = props.groups[props.category.group]
	const set = props.sets[group.set]

	return (
		<div>
			<b style={{ fontSize: '1.4rem', display: 'block', marginTop: '1rem' }}>
				-{' '}
				<small>
					{set.name} : {group.name}
				</small>{' '}
				: {props.category.name}
				<button onClick={props.resetMappingForCategory} style={{ marginLeft: '1rem' }}>
					RESET
				</button>
			</b>

			<div>
				{Object.entries(props.category.mappingPalette || {}).map(([mappingPaletteId, mappingCategoryToPalette]) => {
					const palette = props.palette[mappingCategoryToPalette.palette]
					const firstColorEntry: ColorEntry | undefined = Object.values(palette.colors).sort(
						(a, b) => a.order - b.order
					)[0]

					const empty = new Array(palette.variations).fill(undefined)
					const elementColors =
						props.elementMappingCategoryPaletteColors &&
						props.elementMappingCategoryPaletteColors[props.category.id] &&
						props.elementMappingCategoryPaletteColors[props.category.id][mappingPaletteId]
							? props.elementMappingCategoryPaletteColors[props.category.id][mappingPaletteId]
							: empty

					return (
						<div key={mappingPaletteId} style={{ padding: '0.1rem 1rem' }}>
							<div>
								<small>Palette</small>: <i>{mappingCategoryToPalette.name}</i>
							</div>
							<Flex gap="0.25rem">
								<Color
									size="1rem"
									color={undefined}
									onMouseOver={() => props.onHighlight(undefined)}
									onMouseOut={() => props.onDeHighlight()}
									onClick={() =>
										props.addMappingCategoryPaletteColors(props.category.id, mappingPaletteId, palette.id, undefined)
									}
								/>
								{possibleColorsForCategory.map(color => (
									<Color
										size="1rem"
										key={color}
										color={color}
										onMouseOver={() => props.onHighlight(color)}
										onMouseOut={() => props.onDeHighlight()}
										onClick={() =>
											props.addMappingCategoryPaletteColors(props.category.id, mappingPaletteId, palette.id, color)
										}
									/>
								))}
							</Flex>
							<small>Palette Esempio -&gt; Colori selezionati</small>
							<div>
								<span
									onMouseOver={() =>
										props.onHighlight(
											//@ts-ignore
											(elementColors || []).reduce<{ [name: string]: string }>(
												(a: { [name: string]: string }, b: string, index: number) => {
													a[b] = firstColorEntry.values[index]
													return a
												},
												{}
											)
										)
									}
									onMouseOut={() => props.onDeHighlight()}
								>
									<StaticPaletteView value={firstColorEntry} />
								</span>
								-&gt;
								<StaticPaletteView noPrimary value={elementColors} />
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		sets: state.resources.set,
		groups: state.resources.group,
	}))(MappingCategoryPaletteColorsCategory)
)
