import { ICategory, IGroup, ISet } from '@faceyourmanga/fym-lib/dist'
import * as React from 'react'

interface IShowResourcePathProps {
	path: IPath
}

interface IPath {
	set?: ISet
	group?: IGroup
	category?: ICategory
}

const ShowResourcePath: React.FunctionComponent<IShowResourcePathProps> = ({ path }) => {
	return (
		<ul className="show-resource-path">
			{path.set && (
				<React.Fragment>
					<li className="show-resource-path__item">{path.set.name}</li>
					{path.group && (
						<React.Fragment>
							<li className="show-resource-path__item">{path.group.name}</li>
							{path.category && <li className="show-resource-path__item">{path.category.name}</li>}
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</ul>
	)
}

export default React.memo(ShowResourcePath)
