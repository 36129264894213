import * as React from 'react'
import { connect } from 'react-redux'

import { JSONSchema7 } from 'json-schema'

import { PrivateApi } from '@faceyourmanga/fym-shared'
import { addResource, removeResource, updateResource } from '../../redux/resources/actions'
import { IRootState } from '../../types/store'

import { IMixed, IResources } from '@faceyourmanga/fym-lib'
import ConfirmPromise from './Confirm'
import SetForm from '../forms/resources/SetForm'
import GroupForm from '../forms/resources/GroupForm'
import CategoryForm from '../forms/resources/CategoryForm/CategoryForm'
import AlertPromise from './Alert'

interface ICreateUpdateResource {
	resources: IResources
	initialData: Partial<IMixed> | undefined
	type: keyof IResources
	$definitions?: Record<string, JSONSchema7>
	$schema?: JSONSchema7
	onSuccess?: (data: any) => void
	addResource: (item: IMixed) => void
	updateResource: (item: IMixed) => void
	removeResource: (item: IMixed) => void
}

const CreateUpdateResource = (props: ICreateUpdateResource) => {
	// if (!props.$schema) return null

	// const schema = { ...props.$schema, definitions: props.$definitions }
	const [errors, setErrors] = React.useState<any>(null)

	const mode = props.initialData && props.initialData.id ? 'update' : 'create'

	async function onSubmit(data: Partial<IMixed>) {
		try {
			if (props.initialData && props.initialData.id) {
				const updatedItem: IMixed = await PrivateApi.put<IMixed>(
					'v1/resources/' + props.type.toLowerCase() + '/' + props.initialData.id,
					data
				)
				props.updateResource(updatedItem)

				props.onSuccess && props.onSuccess(updatedItem)
			} else {
				const newItem: IMixed = await PrivateApi.post<IMixed>('v1/resources/' + props.type.toLowerCase(), data)
				props.addResource(newItem)

				props.onSuccess && props.onSuccess(newItem)
			}

			setErrors(null)
		} catch (e: any) {
			setErrors(e.data.message.split(','))
		}
	}

	async function onRemove() {
		if (props.initialData && props.initialData.id) {
			if (await ConfirmPromise('Cancellare la risorsa?')) {
				try {
					await PrivateApi.delete<IMixed>('v1/resources/' + props.type.toLowerCase() + '/' + props.initialData.id)
					props.removeResource(props.initialData as IMixed)
					props.onSuccess && props.onSuccess(props.initialData)
				} catch (e: any) {
					await AlertPromise('impossibile completare la richiesta:\n ' + (typeof e === 'string' ? e : e.toString()))
				}
			}
		}
	}

	const formProps: IResourceForm = {
		value: props.initialData,
		mode,
		onSubmit,
		onRemove,
		errors,
		resources: props.resources,
	}

	let children

	switch (props.type) {
		case 'set':
			children = SetForm(formProps)
			break
		case 'group':
			children = GroupForm(formProps)
			break
		case 'category':
			children = CategoryForm(formProps)
			break
		default:
			break
	}

	return (
		<div className="create-update-resource-form">
			<h2 className="create-update-resource-form__title">
				{mode === 'update' ? 'Modifica' : 'Crea'} "{props.type}"
			</h2>
			{children}
		</div>
	)
}

export interface IResourceForm {
	value: Partial<IMixed> | undefined
	mode: 'create' | 'update'
	onSubmit: (data: Partial<IMixed>) => Promise<void>
	onRemove: () => void
	errors: null | Array<string>
	resources: IResources
}

export default connect(
	(store: IRootState, props: { type: keyof IResources; initialData: Partial<IMixed> }) => {
		return {
			resources: store.resources,
		}
	},
	(dispatch, props: { type: keyof IResources }) => ({
		addResource: (item: IMixed) => dispatch(addResource(props.type, item)),
		updateResource: (item: IMixed) => dispatch(updateResource(props.type, item)),
		removeResource: (item: IMixed) => dispatch(removeResource(props.type, item)),
	})
)(CreateUpdateResource)
