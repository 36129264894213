import { ProtocolPing, Data, ProtocolPingPing, ProtocolPingPong } from '@faceyourmanga/fym-lib/dist'

import { ClientProto } from '@faceyourmanga/fym-lib'

export default class ProtoPing extends ClientProto<ProtocolPing> {
	constructor() {
		super('protocolPing', ['ping'])
	}

	onAction(action: ProtocolPing['action'], data: Data<ProtocolPing>) {
		console.log('action', action, data)
		switch (action) {
			case 'ping':
				return this.onActionPing(data)
		}
	}

	onActionPing(data: Data<ProtocolPingPing>) {
		console.log(`received ping ${data}`)

		this.send<ProtocolPingPong>('pong', {})
	}
}
