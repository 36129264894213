import { MessagesActionTypes, SHOW_MESSAGE, HIDE_MESSAGE } from './types'
import { TAppMessages } from '../../types/store'

export const initialMessageState: TAppMessages = {}

export function messageReducer(state = initialMessageState, action: MessagesActionTypes): TAppMessages {
	switch (action.type) {
		case SHOW_MESSAGE: {
			const id: number = action.message_id
			const message = { id, message: action.message, data: action.data }
			return { ...state, [id]: message }
		}

		case HIDE_MESSAGE: {
			const messages = { ...state }
			delete messages[action.message_id]
			return { ...messages }
		}

		default:
			return state
	}
}
