import { ClientSVGSetting, ImageBounding, SVG } from '@faceyourmanga/fym-draw/dist/browser'
import { ElementId, IElement, IMask, MappingCategoryPaletteColors, SetId } from '@faceyourmanga/fym-lib/dist'
import { PrivateApi } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { connect } from 'react-redux'
import { addResource, removeResource, updateResource } from '../../../../redux/resources/actions'
import { IRootState } from '../../../../types/store'
import ConfirmPromise from '../../../components/Confirm'
import { Openable, OpenableHook } from '../../../components/Openable'
import { getSetIdFromCategoryId } from '../../../components/PathUtilities'
import dispatchMessage from '../../../messages/dispatchMessage'
import ElementMeta from './ElementMeta'
import ElementSVG from './ElementSVG'
import Masks from './Masks'
import Transform from './Transform'
import Links from './Links'

interface IElementProps {
	elements: Record<ElementId, IElement>
	element: Partial<IElement> | null
	addResource: (item: IElement) => void
	updateResource: (item: IElement) => void
	removeResource: (item: IElement) => void
	close: () => void
}

function getSortedAndStringColors(svg: string): string {
	return JSON.stringify(SVG.getColors(svg).sort((a, b) => a.localeCompare(b)))
}

function getSameMapping(
	elements: Record<ElementId, IElement>,
	current: Partial<IElement> | null
): MappingCategoryPaletteColors | undefined {
	if (!current || !current.svg || !current.categories || current.categories.length !== 1) {
		return undefined
	}
	const category = current.categories[0]

	const elementSameCategory = Object.values(elements).filter(
		elements => elements.categories && elements.categories[0] === category
	)

	if (elementSameCategory.length > 0) {
		const currentElementColors = getSortedAndStringColors(current.svg)
		const elementsSameColors = elementSameCategory.filter(
			e => getSortedAndStringColors(e.svg) === currentElementColors && e.mappingCategoryPaletteColors
		)

		if (elementsSameColors.length > 0) {
			return elementsSameColors[0].mappingCategoryPaletteColors
		}
	}
}

function Element(props: IElementProps) {
	const [element, setElement] = React.useState<Partial<IElement>>({
		mappingCategoryPaletteColors: undefined,
		categories: [],
		masks: [],
		visible: false,
		zIndex: 0,
		...props.element,
	})

	React.useEffect(() => {
		if (element.svg && !element.mappingCategoryPaletteColors) {
			setElement({ ...element, mappingCategoryPaletteColors: getSameMapping(props.elements, element) })
		}
	}, [element.svg, element.mappingCategoryPaletteColors, props.elements])

	const [errors, setErrors] = React.useState<Array<string>>([])

	const bUpdate = !!element.id

	const slides = {
		Meta: OpenableHook(true),
		Svg: OpenableHook(false),
		Links: OpenableHook(false),
		Mask: OpenableHook(false),
		Transform: OpenableHook(false),
	}

	function open(type: keyof typeof slides) {
		Object.keys(slides).forEach(k => slides[k as keyof typeof slides][2](false))
		slides[type][2](true)
	}

	const setId: SetId | undefined =
		element.categories && element.categories.length > 0 ? getSetIdFromCategoryId(element.categories[0]) : undefined

	async function save() {
		const elementToStore = { ...element }

		if (elementToStore.svg) {
			const optimizedSVG = await SVG.optimize(elementToStore.svg)
			const bounding = ImageBounding.getSVGElementsBounding(SVG.removeSVGClientTrack(elementToStore.svg)) || [
				0,
				0,
				ClientSVGSetting.width,
				ClientSVGSetting.height,
			]

			// convert bounding to percentage
			bounding[0] /= ClientSVGSetting.width
			bounding[1] /= ClientSVGSetting.height
			bounding[2] /= ClientSVGSetting.width
			bounding[3] /= ClientSVGSetting.height

			if (optimizedSVG && optimizedSVG.data && optimizedSVG.data.length > 0) {
				elementToStore.svg = optimizedSVG.data
			}

			if (bounding && bounding[0] === 0 && bounding[1] === 0 && bounding[2] === 1 && bounding[3] === 1) {
				if (
					(await ConfirmPromise(
						"Il bounding dell'elemento comprende tutta l'area visibile, hai utilizzato il template per l'svg e vuoi continuare?"
					)) === false
				) {
					return
				}
			}

			elementToStore.svgBounding = bounding
		}

		if (bUpdate && elementToStore.id) {
			try {
				const result = await PrivateApi.put<IElement>(`v1/resources/element/${elementToStore.id}`, elementToStore)
				props.updateResource(result)
				props.close()
			} catch (e: any) {
				console.log('update errors', e)
				dispatchMessage(`<h2>Errore update elemento</h2><div>${e.data?.message || e.message}</div>`, {
					type: 'error',
				})
			}
		} else {
			try {
				const newElement: IElement = await PrivateApi.post<IElement>('v1/resources/element', elementToStore)
				props.updateResource(newElement)
				props.close()
			} catch (e: any) {
				console.log('save errors', e)
				setErrors(e.data.validation.map((e: any) => e.message))
				dispatchMessage(`<h2>Errore salvataggio elemento</h2><div>${e.data?.message || e.message}</div>`, {
					type: 'error',
				})
			}
		}
	}

	async function remove() {
		console.log(props.element)
		if (props.element && props.element.id && (await ConfirmPromise('delete?'))) {
			await PrivateApi.delete<IElement>('v1/resources/element/' + element.id)
			props.removeResource(props.element as IElement)
			props.close()
		}
	}

	return (
		<div className="create-element">
			<h2 className="create-element__title">{bUpdate ? 'Update' : 'Create new'} element</h2>
			<div className="create-element__content">
				<div className="create-element__content__head">
					{Object.keys(slides).map(k => (
						<div
							key={k}
							className={`create-element__content__head__item ${
								slides[k as keyof typeof slides][0] ? 'create-element__content__head__item--open' : ''
							}`}
							onClick={() => open(k as keyof typeof slides)}
						>
							{k}
						</div>
					))}
				</div>

				<div className="create-element__content__content">
					<Openable status={slides.Meta[0]}>
						<ElementMeta element={element} setElement={setElement} />
					</Openable>

					<Openable status={slides.Links[0]}>
						<Links
							element={element}
							setId={setId}
							onSelect={requiredOtherElementsIds => setElement({ ...element, requiredOtherElementsIds })}
						/>
					</Openable>

					<Openable status={slides.Mask[0]}>
						<Masks
							element={element}
							setId={setId}
							selecteds={element.masks as Array<IMask>}
							onSelect={masks => setElement({ ...element, masks })}
						/>
					</Openable>
					<Openable status={slides.Svg[0]}>
						<ElementSVG element={element} setElement={setElement} />
					</Openable>

					<Openable status={slides.Transform[0]}>
						<Transform
							element={element}
							setId={setId}
							selected={element.transform}
							onSelect={transform => setElement({ ...element, transform: transform ? transform.id : undefined })}
						/>
					</Openable>
				</div>

				<div className="create-element__content__footer">
					<div>
						<button onClick={() => save()}>{bUpdate ? 'Aggiorna' : 'Crea'}</button>
						{bUpdate && <button onClick={() => remove()}>Delete</button>}
					</div>
					<div>
						{errors.map((e, i) => (
							<div key={i}>{e}</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default React.memo(
	connect(
		(state: IRootState) => ({
			elements: state.resources.element,
		}),
		dispatch => ({
			addResource: (item: IElement) => dispatch(addResource('element', item)),
			updateResource: (item: IElement) => dispatch(updateResource('element', item)),
			removeResource: (item: IElement) => dispatch(removeResource('element', item)),
		})
	)(Element)
)
