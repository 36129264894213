import * as React from 'react'
import { connect } from 'react-redux'

import { ICategory, IElement, IMask, IResources, SetId } from '@faceyourmanga/fym-lib/dist'
import { IRootState } from '../../../../types/store'
import { IPath } from '../../../../types/resources'
import { SVG } from '@faceyourmanga/fym-draw'

import MaskPreview from './MaskPreview'
import dispatchMessage from '../../../messages/dispatchMessage'

interface IMasksProps {
	categories: Record<string, ICategory>
	resources: IResources
	setId: string | undefined | null
	selecteds: Array<IMask>
	element: Partial<IElement>
	onSelect: (masks: Array<IMask>) => void
}

const Masks = (props: IMasksProps) => {
	const [masks, setMasks] = React.useState<Array<IMask>>(props.selecteds)

	function addMasking(index: number, path: IPath) {
		const umasks = [...masks]
		const m = umasks[index]

		if (path.category && !m.masking.includes(path.category.id)) {
			m.masking.push(path.category.id)
			setMasks(umasks)
		}

		checkUpdates(umasks)
	}

	function addPathFromSVG(index: number, svg: string) {
		const umasks = [...masks]
		const m = umasks[index]

		const removeTrack = SVG.removeSVGClientTrack(svg)
		const svgElement = SVG.parseFromString(removeTrack)

		if (svgElement) {
			const paths = svgElement.getElementsByTagName('path')

			if (paths.length !== 1) {
				dispatchMessage(
					`Hai caricato una maschera che oltre al tracciato di riferimento contiene altre path ${paths.length}`,
					{ type: 'error' }
				)
			} else {
				// TODO: check path of container
				const path = paths[0].getAttribute('d')
				if (path && path.length > 0) {
					m.path = path
					setMasks(umasks)
					checkUpdates(umasks)
				} else {
					dispatchMessage(`errore maschera`, { type: 'error' })
				}
			}
		}
	}

	function checkUpdates(umasks: Array<IMask>) {
		props.onSelect(umasks.filter(mask => mask.path.length > 0 && mask.masking.length > 0))
	}

	function remove(index: number) {
		const umasks = [...masks]
		umasks.splice(index, 1)
		setMasks(umasks)
		checkUpdates(umasks)
	}

	function createMask() {
		for (let i = 0; i < masks.length; i++) {
			const mask = masks[i]
			if (mask.path.length === 0 || mask.masking.length === 0) return
		}

		setMasks([...masks, { path: '', masking: [] }])
	}

	function categoryIdToIPath(categoryId: string): IPath {
		const category = props.resources.category[categoryId]
		const group = props.resources.group[category.group]

		return {
			category,
			group,
			set: props.resources.set[group.set],
		}
	}

	function removeMaskPath(index: number) {
		const umasks = [...masks]
		umasks[index].path = ''
		setMasks(umasks)
	}

	function removeCategory(index: number, categoryIndex: number) {
		const umasks = [...masks]
		umasks[index].masking.splice(categoryIndex, 1)
		setMasks(umasks)
	}

	return (
		<React.Fragment>
			<div style={{ margin: '1rem 0' }}>
				Puoi aggiungere più maschere e per ognuna di essere puoi scegliere le categorie a cui deve essere applicata
				<div>
					<small>
						<i>
							La maschera deve essere un solo tracciato grande tutto di colore nero e lasciare in bianco la parte che
							deve essere mascherata nella categoria.
						</i>
					</small>
				</div>
			</div>
			<div>
				{masks && masks.length > 0 ? (
					<div>
						<div style={{ margin: '1rem 0' }}>
							{masks.length} {masks.length === 1 ? 'maschera selezionata' : 'maschere selezionate'}:
						</div>

						{masks.map((mask, index) => (
							<MaskPreview
								setId={props.setId}
								element={props.element}
								resources={props.resources}
								key={index}
								mask={mask}
								addMasking={path => addMasking(index, path)}
								addPathFromSVG={svg => addPathFromSVG(index, svg)}
								categoryIdToIPath={categoryIdToIPath}
								removeCategory={categoryIndex => removeCategory(index, categoryIndex)}
								removeMaskPath={() => removeMaskPath(index)}
								remove={() => remove(index)}
							/>
						))}
					</div>
				) : (
					<div style={{ margin: '1rem 0' }}>Nessuna maschera selezionata</div>
				)}

				<div style={{ marginTop: '1rem' }}>
					<button onClick={createMask}>Aggiungi maschera</button>
				</div>
			</div>
		</React.Fragment>
	)
}

export default connect((state: IRootState) => ({
	categories: state.resources.category,
	resources: state.resources,
}))(Masks)
