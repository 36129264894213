import * as React from 'react'
import UploadSVG from '../../../components/UploadSVG'

interface ISVGPreviewProps {
	elementName?: string
	svg?: string
	setSVG: (svg?: string) => void
}

function SVGPreview(props: ISVGPreviewProps) {
	if (!props.svg) {
		return <UploadSVG setSVG={props.setSVG} />
	}

	function download() {
		if (props.svg) {
			const blob = new Blob([props.svg], {
				type: 'image/svg+xml;charset=utf-8',
			})

			const a = document.createElement('a')

			a.target = '_blank'
			a.download = props.elementName || 'svg'
			a.href = URL.createObjectURL(blob)

			document.body.appendChild(a)
			a.click()
			a.remove()
		}
	}

	return (
		<React.Fragment>
			<div className="create-element__slide__svg__preview__svg" dangerouslySetInnerHTML={{ __html: props.svg }}></div>
			<button
				className="button--secondary create-element__slide__svg__preview__remove"
				onClick={() => props.setSVG(undefined)}
			>
				remove
			</button>
			<button className="button--secondary create-element__slide__svg__preview__remove" onClick={download}>
				download
			</button>
		</React.Fragment>
	)
}

export default React.memo(SVGPreview)

////////////////////////////////
