import * as React from 'react'
import { connect } from 'react-redux'

import { IBaseResource } from '@faceyourmanga/fym-lib/dist/types/index'
import { TiArrowSortedDown } from 'react-icons/ti'
import { AiFillEdit } from 'react-icons/ai'

import Flex from '../../../components/Flex'
import { IRootState } from '../../../../types'
import { joinUrl } from '@faceyourmanga/fym-shared'

interface ICreatorBaseResourceProps extends IBaseResource {
	thumbsPath: string
	visible: boolean
	zIndex?: number
	onEdit?: (e: React.MouseEvent) => void
	onOpen?: () => void
}

const CreatorBaseResource: React.FunctionComponent<ICreatorBaseResourceProps> = props => {
	return (
		<div className="creator-resource">
			<Flex gap="1rem" className="creator-resource__container">
				<img className="creator-resource__image" src={joinUrl(props.thumbsPath, props.thumb)} alt={props.name} />

				<div>
					<h2 className="creator-resource__name">{props.name}</h2>
					<div className="creator-resource__visibility">
						<div
							className={
								'creator-resource__visibility__status ' +
								(props.visible
									? 'creator-resource__visibility__status--visible'
									: 'creator-resource__visibility__status--hidden')
							}
						></div>
						<small>{props.visible ? 'visible' : 'nascosto'}</small>
						{typeof props.zIndex !== 'undefined' && (
							<div>
								<small>zIndex: {props.zIndex}</small>
							</div>
						)}
					</div>
					{props.children}
				</div>
			</Flex>
			{props.onOpen ? (
				<Flex>
					<div className="creator-resource__open" onClick={e => props && props.onOpen && props.onOpen()}>
						<TiArrowSortedDown />
					</div>
					{props.onEdit && (
						<div className="creator-resource__edit" onClick={e => props && props.onEdit && props.onEdit(e)}>
							<AiFillEdit />
						</div>
					)}
				</Flex>
			) : (
				props.onEdit && (
					<div className="creator-resource__edit" onClick={e => props && props.onEdit && props.onEdit(e)}>
						<AiFillEdit />
					</div>
				)
			)}
		</div>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		thumbsPath: state.hosts.thumbs,
	}))(CreatorBaseResource)
)
