import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { AiOutlineClose } from 'react-icons/ai'

interface ModalProps {
	open: boolean
	nopadding?: boolean
	noCloseButton?: boolean
	children: JSX.Element
	close?: () => void
	bCloseOnBackground?: boolean
	bCloseOnEsc?: boolean
}

const Modal: React.FunctionComponent<ModalProps> = props => {
	const modalRoot: HTMLElement = document.getElementById('modal-root') as HTMLElement
	const container: HTMLDivElement = document.createElement('div')

	React.useEffect(() => {
		modalRoot.appendChild(container)

		function handleEsc(e: KeyboardEvent) {
			if (e.key === 'Escape' && props.bCloseOnEsc) {
				props.close && props.close()
			}
		}

		document.addEventListener('keydown', handleEsc, { passive: true })

		return () => {
			document.removeEventListener('keydown', handleEsc)
			modalRoot.removeChild(container)
		}
	}, [container, modalRoot, props.close])

	return props.open
		? ReactDOM.createPortal(
				<div className={'modal' + (props.nopadding ? ' modal--nopadding' : '')}>
					<div className="modal__background" onClick={e => props.bCloseOnBackground && props.close && props.close()} />
					<div className="modal__content">
						{!props.noCloseButton && (
							<div className="modal__content__close_btn" onClick={() => props.close && props.close()}>
								<AiOutlineClose />
							</div>
						)}
						{props.children}
					</div>
				</div>,
				container
		  )
		: null
}

export default React.memo(Modal)
