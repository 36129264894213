import * as React from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'

import Loading from '../Loading'
import { IWithExistUser } from '../types/components'
import { IRootState } from '../types/store'

import Users from './pages/users/Users'
import Creators from './pages/creators/Creators'
import Payments from './pages/payments/Payments'
import GrantRoute from './GrantRoute'
import { IUser } from '@faceyourmanga/fym-lib/src'

/**
 * Authenticated routes
 *
 * @param {IWithExistUser} props
 * @returns
 */
function Router(props: IWithExistUser) {
	return (
		<React.Suspense fallback={<Loading />}>
			<Switch>
				<GrantRoute path="/creators/:setId?/:groupId?/:categoryId?" scopes={['editor']} Component={Creators} />
				{/* <Route path="/creators/:setId?/:groupId?/:categoryId?" component={Creators} /> */}
				<GrantRoute path="/users" scopes={['admin']} Component={Users} />
				<GrantRoute path="/payments" scopes={['business']} Component={Payments} />
				<Route exact path="/" component={() => <Homepage user={props.user} />} />
			</Switch>
		</React.Suspense>
	)
}

function Homepage({ user }: IWithExistUser) {
	const admin = user.scopes.includes('admin')
	return (
		<div className="app__content__homepage">
			<div>
				<h1>Bentornato {user.username || user.nickname}!</h1>
				<div>
					{(admin || user.scopes.includes('moderator')) && <Link to="/users">Gestione Utenti</Link>}
					{(admin || user.scopes.includes('editor')) && <Link to="/creators">Gestione Risorse</Link>}
					{(admin || user.scopes.includes('business')) && <Link to="/payments">Gestion Pagamenti</Link>}
				</div>
			</div>
		</div>
	)
}

export default React.memo(Router)
