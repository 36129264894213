import * as React from 'react'

import { ClientSVGSetting, SVG } from '@faceyourmanga/fym-draw'
import { IElement, IMutableColors } from '@faceyourmanga/fym-lib/dist'

import MappingCategoryPaletteColors from './MappingCategoryPaletteColors'
import SVGPreview from './SVGPreview'
import Thumb from './Thumb'
import Flex from '../../../components/Flex'
import { IRootState } from '../../../../types'
import { connect } from 'react-redux'
import { IResourcesMap } from '@faceyourmanga/fym-lib'

const HIGHLIGHT_COLOR = '#ea4b74'

interface IElementSVGProps {
	element: Partial<IElement>
	setElement: (element: Partial<IElement>) => void
	resources: IResourcesMap
}

const ElementSVG = ({ element, setElement, resources }: IElementSVGProps) => {
	const [currentSVG, setCurrentSVG] = React.useState<string | undefined>(element.svg)
	const [colors, setColors] = React.useState<Array<string>>(SVG.getColors(currentSVG || ''))

	function setSVG(svg: string | undefined) {
		setElement({ ...element, svg })
		setCurrentSVG(svg)
		setColors(SVG.getColors(svg || ''))
	}

	function highlight(color: string | undefined | { [currentColor: string]: string }) {
		if (currentSVG && color) {
			if (typeof color === 'string' && color.length && SVG.isHEX(color)) {
				setCurrentSVG(currentSVG.replace(new RegExp(color, 'ig'), HIGHLIGHT_COLOR))
			} else if (typeof color !== 'undefined') {
				let svg = currentSVG
				Object.keys(color).forEach((k: string) => {
					const currentColor = (color as any)[k] as string
					if (k && k.length && SVG.isHEX(k) && currentColor && currentColor.length && SVG.isHEX(currentColor))
						svg = svg.replace(new RegExp(k, 'ig'), currentColor)
				})
				setCurrentSVG(svg)
			}
		}
	}

	function dehighlight() {
		currentSVG && setCurrentSVG(element.svg)
	}

	function downloadTemplate(e: React.MouseEvent) {
		e.preventDefault()

		const blob = new Blob([ClientSVGSetting.svgTemplate], {
			type: 'image/svg+xml;charset=utf-8',
		})

		const a = document.createElement('a')

		a.target = '_blank'
		a.download = 'FaceYourManga SVG Template'
		a.href = URL.createObjectURL(blob)

		document.body.appendChild(a)
		a.click()
		a.remove()
	}

	return (
		<div className="create-element__slide__svg">
			<div style={{ marginBottom: '1rem' }}>
				È importante che l'svg che si sta caricando sia messo in un tracciato {ClientSVGSetting.width}x
				{ClientSVGSetting.height} pixel.
				<br />
				Puoi scaricare <b onClick={downloadTemplate}>qui</b> il il file di base
				<br />
			</div>

			<Flex gap="3rem">
				<div className="create-element__slide__svg__preview">
					<SVGPreview elementName={element.name} svg={currentSVG} setSVG={setSVG} />
				</div>

				<div style={{ flex: 1, display: 'flex', gap: '1rem', flexDirection: 'column' }}>
					<div className="create-element__slide__svg__thumb">
						{currentSVG && (
							<Thumb
								categories={element.categories || []}
								thumb={element.thumb}
								// links={(element.requiredOtherElementsIds || []).map(id => resources.element[id])}
								svg={element.svg as string}
								onThumb={thumb => setElement({ ...element, thumb })}
							/>
						)}
					</div>
					<div className="create-element__slide__svg__mutable-colors">
						{currentSVG && (
							<MappingCategoryPaletteColors
								value={element.mappingCategoryPaletteColors}
								colors={colors}
								elementCategories={element.categories || []}
								onHighlight={highlight}
								onDeHighlight={dehighlight}
								onSelect={mappingCategoryPaletteColors => setElement({ ...element, mappingCategoryPaletteColors })}
							/>
						)}
					</div>
				</div>
			</Flex>
		</div>
	)
}

export default connect((state: IRootState) => ({
	resources: state.resources,
}))(ElementSVG)
