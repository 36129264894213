import * as React from 'react'
import ColorPick from '../../components/ColorPick'
import { AiOutlineSave, AiOutlineWarning } from 'react-icons/ai'
import { ColorEntry } from '@faceyourmanga/fym-lib'

interface ISinglePaletteForm {
	value: ColorEntry
	onChange: (color: ColorEntry) => void
	variations: number
}

function copy(color: ColorEntry, variations: number): ColorEntry {
	const _initial = JSON.parse(JSON.stringify(color || {}))
	if (!_initial.values) _initial.values = new Array(variations).fill(undefined)
	if (!_initial.order) _initial.order = 0

	return _initial
}

const SinglePaletteForm = (props: ISinglePaletteForm) => {
	const _initial = copy(props.value, props.variations)

	const [state, setState] = React.useState<Partial<ColorEntry>>(_initial)

	React.useEffect(() => {
		setState(copy(props.value, props.variations))
	}, [props.value, props.variations])

	function addColor(index: number, color: string) {
		const empty = new Array(props.variations).fill(undefined)

		const colors = state.values || empty

		colors[index] = color

		setState({ ...state, values: [...colors] })
	}

	return (
		<div className="paletteRow">
			{state.values && state.values.length !== props.variations && (
				<div className="paletteRow__warning">
					<AiOutlineWarning className="icon" />
				</div>
			)}
			<div className="paletteRow__color">
				<ColorPick value={state.colorId || ''} onChange={colorId => setState({ ...state, colorId })} />
			</div>
			<div className="paletteRow__variationsContainer">
				<div className="paletteRow__variations">
					{new Array(props.variations).fill(0).map((v, i) => (
						<div key={i} className="paletteRow__variations__variation">
							<ColorPick value={(state.values as Array<any>)[i]} onChange={color => addColor(i, color)} />
						</div>
					))}
				</div>
			</div>
			<div className="paletteRow__save">
				<button onClick={() => props.onChange(state as ColorEntry)}>
					<AiOutlineSave className="icon" />
				</button>
			</div>
		</div>
	)
}

export default SinglePaletteForm
