import * as React from 'react'
import { ColorEntry, Colors, IPalette } from '@faceyourmanga/fym-lib'
import { AiOutlineDelete } from 'react-icons/ai'
import Flex from '../../../components/Flex'
import SinglePaletteForm from '../../../forms/resources/SinglePaletteForm'
import { ReactSortable } from 'react-sortablejs'

interface IPaletteColorsProps {
	palette: IPalette
	save: (value: IPalette) => void
	updatePalette: (colorEntryId: string, value: ColorEntry) => void
	deletePaletteColor: (paletteColor: ColorEntry) => void
}

const PaletteColors = (props: IPaletteColorsProps) => {
	const [list, setList] = React.useState<Array<ColorEntry & { id: string }>>(
		Object.values(props.palette.colors)
			.map(e => ({ ...e, id: e.colorId }))
			.sort((a, b) => a.order - b.order)
	)

	function onEnd() {
		const t: Array<ColorEntry> = list.map(l => ({ ...l, id: undefined }))
		for (let i = 0, len = list.length; i < len; i++) {
			t[i].order = i
		}

		const colors = t.reduce((acc, current) => {
			acc[current.colorId] = current
			return acc
		}, {} as Colors)

		props.save({ ...props.palette, colors })
	}

	React.useEffect(() => {
		console.log('props.palette.colors', props.palette.colors)
		Object.values(props.palette.colors)
			.map(e => ({ ...e, id: e.colorId }))
			.sort((a, b) => a.order - b.order)
	}, [props.palette.colors])

	return (
		<ReactSortable
			animation={200}
			className="sortable"
			list={list}
			setList={setList}
			onEnd={onEnd}
			handle=".sortable__item__handle"
		>
			{list.map(colorEntry => (
				<div key={colorEntry.colorId + '-' + colorEntry.values.join('-')} className={`sortable__item`}>
					<div className="sortable__item__handle"></div>
					<div className="sortable__item__content">
						<Flex gap="0.2rem">
							<SinglePaletteForm
								value={colorEntry}
								variations={props.palette.variations}
								onChange={value => props.updatePalette(value.colorId, value)}
							/>

							<AiOutlineDelete className="icon icon--cliccable" onClick={() => props.deletePaletteColor(colorEntry)} />
						</Flex>
					</div>
				</div>
			))}
		</ReactSortable>
	)
}

export default React.memo(PaletteColors)
