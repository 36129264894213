import { CategoryId, ICategory, IResources } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { MdClose } from 'react-icons/md'
import ResourcePathSelector from '../../../components/ResourcePath/ResourcePathSelector'
import SelectOrCreateResource from '../../../components/ResourcePath/SelectOrCreateResource'

interface CategoryFormTransformDependsOnProps {
	resources: IResources
	category: Partial<ICategory>
	onChange: (name: string, value: any) => void
}

function CategoryFormTransformDependsOn({ category, resources, onChange }: CategoryFormTransformDependsOnProps) {
	function addCategoryDependency(
		categoryId: CategoryId,
		category: Partial<ICategory>,
		onChange: (name: string, value: any) => void
	) {
		const depends = category.dependsOn || []
		if (!depends.includes(categoryId)) {
			onChange('dependsOn', [...depends, categoryId])
		}
	}

	function removeCategoryDependency(
		categoryId: CategoryId,
		category: Partial<ICategory>,
		onChange: (name: string, value: any) => void
	) {
		const depends = category.dependsOn || []

		if (depends.includes(categoryId)) {
			depends.splice(depends.indexOf(categoryId, 1))
			onChange('dependsOn', [...depends])
		}
	}

	return (
		<div>
			<small>
				Gli elementi di questa categoria devono dipendere
				<br />
				dalle trasformazioni di un'altra categoria?
			</small>
			<ul style={{ margin: 0 }}>
				{category.dependsOn &&
					category.dependsOn.length > 0 &&
					category.dependsOn.map(dependsCategoryId => (
						<li key={dependsCategoryId}>
							{resources.category[dependsCategoryId].name}
							<MdClose onClick={() => removeCategoryDependency(dependsCategoryId, category, onChange)} />
						</li>
					))}
			</ul>
			<div>
				<ResourcePathSelector
					level={2}
					onSelect={path => path.category && addCategoryDependency(path.category.id, category, onChange)}
				/>
			</div>
		</div>
	)
}

export default React.memo(CategoryFormTransformDependsOn)
