import {
	CategoryId,
	GroupId,
	ICategory,
	IElement,
	IGroup,
	IMixed,
	IResources,
	ISet,
	SetId,
} from '@faceyourmanga/fym-lib/dist'

import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import SortableResource from '../../../components/SortableResource'
import { IPath, IPathIds } from '../../../../types/resources'
import CreatorBaseResource from './CreatorBaseResource'

import { GrAdd } from 'react-icons/gr'
import Modal from '../../../components/Modal'
import CreateResource from '../../../components/CreateUpdateResource'

interface ICreatorResourcesProps {
	resources: IResources
	createUpdateElement: (element: IElement | undefined, categoryId?: string) => void
}

interface IParams {
	setId?: SetId
	groupId?: GroupId
	categoryId?: CategoryId
}

function CreatorResources(props: RouteComponentProps<IParams> & ICreatorResourcesProps) {
	const [path, setPath] = React.useState<IPathIds>({})

	const [createType, setCreateType] =
		React.useState<{
			type: keyof IResources
			initialData: Partial<IMixed>
		} | null>(null)

	function updateResource(e: React.MouseEvent, type: keyof IResources, resource: IMixed) {
		e.preventDefault()
		e.stopPropagation()
		setCreateType({ type, initialData: resource })
	}

	React.useEffect(() => {
		if (props.resources) {
			const newPath: IPathIds = {
				set: props.match.params.setId,
				group: props.match.params.groupId,
				category: props.match.params.categoryId,
			}

			if (JSON.stringify(newPath) !== JSON.stringify(path)) {
				setPath(newPath)
			}
		}
	}, [path, props.resources, props.match.params])

	React.useEffect(() => {
		if (path.set) {
			const loc = [path.set, path.group, path.category].filter(e => e).join('/')
			const final = '/creators/' + loc

			if (final !== props.location.pathname) {
				props.history.push(final, null)
			}
		}
	}, [path, props.history, props.location.pathname])

	function createResource(type: keyof IResources) {
		let initialData = {}

		switch (type) {
			case 'group':
				initialData = { set: path.set }
				break
			case 'category':
				initialData = { group: path.group }
				break
			case 'element':
				initialData = { categories: [path.category] }
				break
		}

		setCreateType({ type, initialData })
	}

	function isCurrentList(type: keyof IResources): boolean {
		switch (type) {
			case 'set':
				return !path.set
			case 'group':
				return !!(path.set && !path.group)
			case 'category':
				return !!(path.group && !path.category)
			case 'element':
				return !!path.category
		}

		return false
	}

	const bindedPath: IPath = {
		set: path.set ? props.resources.set[path.set] : undefined,
		group: path.group ? props.resources.group[path.group] : undefined,
		category: path.category ? props.resources.category[path.category] : undefined,
	}

	return (
		<section className="creator-resources">
			<section className="creator-resources__container">
				{
					// Sets
					<React.Fragment>
						<div className="creator-resources__container__path">
							<span className="creator-resources__container__path__info">Sets</span>/
						</div>
						<div
							className={`creator-resources__container__list ${
								isCurrentList('set') ? 'creator-resources__container__list--active' : ''
							}`}
						>
							<SortableResource selectedId={path.set} type="set" list={Object.values(props.resources.set)}>
								{(set: IMixed) => (
									<CreatorBaseResource
										{...(set as ISet)}
										onEdit={e => updateResource(e, 'set', set)}
										onOpen={() => (path.set && path.set === set.id ? setPath({}) : setPath({ set: set.id }))}
									/>
								)}
							</SortableResource>
							<div className="creator-resources__container__list__add" onClick={() => createResource('set')}>
								<GrAdd size={'2rem'} />
							</div>
						</div>
					</React.Fragment>
				}
				{bindedPath.set && (
					// Groups
					<React.Fragment>
						<div className="creator-resources__container__path">
							<span className="creator-resources__container__path__info">Groups</span>/
							<span>{bindedPath.set.name}</span>
						</div>
						<div
							className={`creator-resources__container__list ${
								isCurrentList('group') ? 'creator-resources__container__list--active' : ''
							}`}
						>
							<SortableResource
								selectedId={bindedPath.group?.id}
								type="group"
								list={bindedPath.set.groups.map(group_id => props.resources.group[group_id])}
							>
								{(group: IMixed) => (
									<CreatorBaseResource
										{...(group as IGroup)}
										onEdit={e => updateResource(e, 'group', group)}
										onOpen={() =>
											bindedPath.group && bindedPath.group.id === group.id
												? setPath({ set: path.set })
												: setPath({ set: path.set, group: group.id })
										}
									/>
								)}
							</SortableResource>
							<div className="creator-resources__container__list__add" onClick={() => createResource('group')}>
								<GrAdd size={'2rem'} />
							</div>
						</div>
					</React.Fragment>
				)}
				{bindedPath.set && bindedPath.group && (
					// Categories
					<React.Fragment>
						<div className="creator-resources__container__path">
							<span className="creator-resources__container__path__info">Categories</span>/
							<span>{bindedPath.set.name}</span>/<span>{bindedPath.group.name}</span>
						</div>
						<div
							className={`creator-resources__container__list ${
								isCurrentList('category') ? 'creator-resources__container__list--active' : ''
							}`}
						>
							<SortableResource
								type="category"
								selectedId={path.category}
								list={bindedPath.group.categories.map(category_id => props.resources.category[category_id])}
							>
								{(category: IMixed) => (
									<CreatorBaseResource
										{...(category as ICategory)}
										onEdit={e => updateResource(e, 'category', category)}
										onOpen={() =>
											bindedPath.category && bindedPath.category.id === category.id
												? setPath({ ...path, category: undefined })
												: setPath({ ...path, category: category.id })
										}
									/>
								)}
							</SortableResource>
							<div className="creator-resources__container__list__add" onClick={() => createResource('category')}>
								<GrAdd size={'2rem'} />
							</div>
						</div>
					</React.Fragment>
				)}
				{bindedPath.set && bindedPath.group && bindedPath.category && (
					// Elements
					<React.Fragment>
						<div className="creator-resources__container__path">
							<span className="creator-resources__container__path__info">Elements</span>/
							<span>{bindedPath.set.name}</span>/<span>{bindedPath.group.name}</span>/
							<span>{bindedPath.category.name}</span>
						</div>
						<div
							className={`creator-resources__container__list ${
								isCurrentList('element') ? 'creator-resources__container__list--active' : ''
							}`}
						>
							<SortableResource
								type="element"
								list={bindedPath.category.elements.map(element_id => props.resources.element[element_id])}
							>
								{(element: IMixed) => (
									<CreatorBaseResource
										onEdit={() => props.createUpdateElement(element as IElement)}
										{...(element as IElement)}
									/>
								)}
							</SortableResource>
							<div
								className="creator-resources__container__list__add"
								onClick={() => props.createUpdateElement(undefined, path.category)}
							>
								<GrAdd size={'2rem'} />
							</div>
						</div>
					</React.Fragment>
				)}
			</section>

			{createType !== null && (
				<Modal open={true} close={() => setCreateType(null)} bCloseOnBackground={false} bCloseOnEsc={true}>
					<CreateResource onSuccess={() => setCreateType(null)} {...createType} />
				</Modal>
			)}
		</section>
	)
}

export default React.memo(withRouter(CreatorResources))
