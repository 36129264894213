import { ClientSVGSetting, SVG } from '@faceyourmanga/fym-draw'
import { CategoryId, ICropping, IElement, IResourcesMap } from '@faceyourmanga/fym-lib/dist'
import { joinUrl, PrivateApi } from '@faceyourmanga/fym-shared'
import { useDraggable, useWheel, clamp } from '@faceyourmanga/fym-shared'
import * as React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../../types/store'
import ConfirmPromise from '../../../components/Confirm'

interface IThumbProps {
	thumbsPath: string
	resources: IResourcesMap
	categories: Array<CategoryId>
	svg: string
	thumb?: string | null
	onThumb: (thumb64?: string) => void
	// links: Array<IElement>
}

// async function mergeSVG(svgImages: Array<string>): Promise<HTMLImageElement> {
// 	const finalSVG = svgImages.shift() as string
// 	const finalImg = await SVG.toImage(finalSVG)

// 	const canvas = document.createElement('canvas')
// 	canvas.width = finalImg.width
// 	canvas.height = finalImg.height
// 	const context = canvas.getContext('2d')!

// 	for (let i = 0; i < svgImages.length; i++) {
// 		const img = await SVG.toImage(svgImages[i])
// 		context.drawImage(img as CanvasImageSource, 0, 0)
// 	}

// 	context.drawImage(finalImg as CanvasImageSource, 0, 0)

// 	const image = new Image()
// 	image.src = canvas.toDataURL('image/png')
// 	return image
// }

async function generateElementThumb(
	canvasRef: HTMLCanvasElement | undefined,
	svgOrImage: string | HTMLImageElement,
	cropping: ICropping
): Promise<HTMLCanvasElement> {
	const image: HTMLImageElement =
		typeof svgOrImage === 'string' ? ((await SVG.toImage(svgOrImage)) as HTMLImageElement) : svgOrImage

	const canvas = typeof canvasRef === 'undefined' ? (document.createElement('canvas') as HTMLCanvasElement) : canvasRef
	canvas.width = cropping.size[0]
	canvas.height = cropping.size[1]

	const center = [cropping.size[0] / 2, cropping.size[1] / 2]
	const context = canvas.getContext('2d') as CanvasRenderingContext2D
	context.clearRect(0, 0, cropping.size[0], cropping.size[1])
	context.save()
	context.translate(center[0], center[1])
	context.scale(cropping.scale, cropping.scale)
	context.drawImage(
		image,
		-center[0] - cropping.translate[0] * center[0],
		-center[1] - cropping.translate[1] * center[1]
	)
	context.restore()

	return canvas
}

const Thumb = (props: IThumbProps) => {
	const canvasRef = React.useRef<HTMLCanvasElement>(null)

	const categoryBounding: { x: number; y: number; scale: number } =
		props.categories && props.categories.length > 0
			? props.resources.category[props.categories[0]].thumbBounding || { x: 0, y: 0, scale: 1 }
			: { x: 0, y: 0, scale: 1 }

	const [cropping, setCropping] = React.useState<ICropping>({
		scale: categoryBounding.scale,
		translate: [categoryBounding.x, categoryBounding.y],
		size: [ClientSVGSetting.thumbSize[0], ClientSVGSetting.thumbSize[1]],
	})
	const [initialTranslate, setInitialTranslate] = React.useState<Array<number>>([0, 0])
	const [image, setImage] = React.useState<HTMLImageElement | null>(null)

	const wrapperRef = useDraggable<HTMLDivElement>({
		onDrag: coords => {
			cropping.translate = [
				clamp(-1, 1, initialTranslate[0] + coords.x / -100 / cropping.scale),
				clamp(-1, 1, initialTranslate[1] + coords.y / -100 / cropping.scale),
			]

			if (cropping.scale === 1) cropping.translate = [0, 0]

			setCropping({ ...cropping })
		},
		onDragEnd: () => {
			setInitialTranslate([cropping.translate[0], cropping.translate[1]])
		},
	})

	useWheel(wrapperRef, (dy, e) => {
		e.preventDefault()

		const currentScale = cropping.scale

		cropping.scale = clamp(1, 10, currentScale + Math.sign(dy) * -1 * (e.shiftKey ? 1 : e.altKey ? 0.01 : 0.1))

		if (cropping.scale === 1) cropping.translate = [0, 0]

		setCropping({ ...cropping })
	})

	React.useEffect(() => {
		if (!props.thumb && canvasRef && canvasRef.current && image) {
			// console.log(offsets, categoryBounding, props.categories, props.resources.category[props.categories[0]])
			generateElementThumb(canvasRef.current, image, cropping)
		}
	}, [props, canvasRef, image, cropping])

	React.useEffect(() => {
		if (props.svg) {
			// if (props.links.length > 0) {
			// 	mergeSVG([props.svg].concat(props.links.map(link => link.svg))).then(setImage)
			// } else {
			SVG.toImage(props.svg).then(image => {
				setImage(image as HTMLImageElement)
			})
			// }
		}
		// }, [props.svg, props.links])
	}, [props.svg])

	function save() {
		if (canvasRef.current) {
			const thumb = canvasRef.current.toDataURL('image/png', 1)
			props.onThumb(thumb)
		}
	}

	const src = props.thumb
		? props.thumb.indexOf('data:image') === 0
			? props.thumb
			: joinUrl(props.thumbsPath, props.thumb)
		: null

	async function generateCategoryThumbs() {
		// if (
		// 	await ConfirmPromise(
		// 		`Sei sicuro di voler modificaare le miniature di tutti gli element delle seguenti categorie? ${props.categories
		// 			.map(cid => props.resources.category[cid].name)
		// 			.join(', ')}`
		// 	)
		// ) {
		const croppingMeta: ICropping = {
			scale: cropping.scale,
			translate: [cropping.translate[0], cropping.translate[1]],
			size: [ClientSVGSetting.width, ClientSVGSetting.height],
		}

		props.categories.forEach(async cid => {
			await PrivateApi.put(`v1/resources/category/${cid}/update_elements_thumbs`, croppingMeta)

			// props.resources.category[cid].elements.forEach(async elementId => {
			// 	const element = { ...props.resources.element[elementId] }
			// 	element.thumb = (await generateElementThumb(undefined, element.svg, croppingMeta)).toDataURL('image/png', 1)
			// 	await PrivateApi.put(`v1/resources/element/${elementId}`, element)
			// })
		})
		// }
	}

	return props.thumb ? (
		<React.Fragment>
			<div className="create-element__slide__svg__thumb__line">
				<img src={src as string} alt="Thumb" className="create-element__slide__svg__thumb__image" />

				<button className="button button--secondary" onClick={() => props.onThumb(undefined)}>
					Generate
				</button>

				<button className="button button--secondary" onClick={() => generateCategoryThumbs()}>
					Setta ritaglio di base per la categoria
				</button>
			</div>
		</React.Fragment>
	) : (
		<React.Fragment>
			<div className="create-element__slide__svg__thumb__line">
				<div ref={wrapperRef} className="create-element__slide__svg__thumb__generate">
					<canvas style={{ pointerEvents: 'none' }} ref={canvasRef}></canvas>
				</div>
				<button onClick={() => save()}>Save</button>
			</div>
		</React.Fragment>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		thumbsPath: state.hosts.thumbs,
		resources: state.resources,
	}))(Thumb)
)
