import React from 'react'
import ReactDOM from 'react-dom'

import './sass/app.scss'
import './extension'

import Boot from './Boot'

ReactDOM.render(
	<React.StrictMode>
		<Boot />
	</React.StrictMode>,
	document.getElementById('root')
)
