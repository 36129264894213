import * as React from 'react'
import { connect } from 'react-redux'

import {
	CategoryId,
	ICategory,
	IGroup,
	IPalette,
	MappingCategoryPaletteColors,
	PaletteId,
} from '@faceyourmanga/fym-lib'
import { IRootState } from '../../../../types/store'
import MappingCategoryPaletteColorsCategory from './MappingCategoryPaletteColorsCategory'
import { SVG } from '@faceyourmanga/fym-draw/dist'

interface IMappingCategoryPaletteColorsProps {
	value: MappingCategoryPaletteColors | undefined
	palette: Record<string, IPalette>
	categories: Record<string, ICategory>
	elementCategories: Array<CategoryId>
	colors: Array<string>
	onHighlight: (color: string | undefined) => void
	onDeHighlight: () => void
	onSelect: (mappingCategoryPaletteColors: MappingCategoryPaletteColors | undefined) => void
}

const MappingCategoryPaletteColorsComponent = (props: IMappingCategoryPaletteColorsProps) => {
	function copy(a: MappingCategoryPaletteColors | undefined): MappingCategoryPaletteColors {
		return JSON.parse(JSON.stringify(a || {}))
	}

	function resetMappingForCategory(categoryId: CategoryId) {
		let _mappingCategoryPaletteColors = copy(props.value)
		delete _mappingCategoryPaletteColors[categoryId]

		props.onSelect(sanitize(_mappingCategoryPaletteColors))
	}

	function addMappingCategoryPaletteColors(
		categoryId: CategoryId,
		mappingPaletteId: string,
		paletteId: PaletteId,
		color: string | undefined
	) {
		let _mappingCategoryPaletteColors = copy(props.value)

		if (typeof _mappingCategoryPaletteColors[categoryId] === 'undefined') _mappingCategoryPaletteColors[categoryId] = {}

		if (typeof _mappingCategoryPaletteColors[categoryId][mappingPaletteId] === 'undefined')
			_mappingCategoryPaletteColors[categoryId][mappingPaletteId] = []

		const maxLength = props.palette[paletteId].variations

		const newColors = new Array(maxLength)
		newColors[maxLength - 1] = color
		for (let i = 0; i < maxLength - 1; i++) {
			const color = _mappingCategoryPaletteColors[categoryId][mappingPaletteId][i + 1]

			newColors[i] = color !== null && typeof color !== 'undefined' && color.length > 0 && SVG.isHEX(color) ? color : ''
		}
		_mappingCategoryPaletteColors[categoryId][mappingPaletteId] = newColors

		props.onSelect(sanitize(_mappingCategoryPaletteColors))
	}

	function sanitize(
		_mappingCategoryPaletteColors: MappingCategoryPaletteColors
	): MappingCategoryPaletteColors | undefined {
		let mpc = copy(_mappingCategoryPaletteColors)

		Object.keys(mpc).forEach(categoryId => {
			const categoryMapping = mpc[categoryId]
			Object.keys(categoryMapping).forEach(mappingPaletteId => {
				const colors = categoryMapping[mappingPaletteId]
				let toDelete = true
				for (let i = 0, len = colors.length; i < len; i++) {
					if (
						typeof colors[i] === 'undefined' ||
						colors[i] === null ||
						colors[i]!.length === 0 ||
						SVG.isHEX(colors[i]!)
					) {
						toDelete = false
						break
					}
				}

				if (toDelete || colors.every(c => !c || !SVG.isHEX(c))) delete categoryMapping[mappingPaletteId]
			})
			if (Object.keys(categoryMapping).length <= 0) {
				delete mpc[categoryId]
			}
		})

		if (Object.keys(mpc).length <= 0) {
			return {}
		}

		return mpc
	}

	return (
		<React.Fragment>
			<div className="create-element__slide__-svg__mutable-colors__selecteds">
				<h3 style={{ margin: 0 }}>Gestione delle palette</h3>
				<div>Puoi selezionare più palette e associare più colori alla stessa (in base alle variazioni)</div>

				{props.elementCategories.map(categoryId => {
					const category = props.categories[categoryId]
					const components = []
					if (typeof category.dependsCategoryPalette !== 'undefined') {
						const dependsCategoryPalette = props.categories[category.dependsCategoryPalette]

						components.push(
							<div key={'m_' + dependsCategoryPalette.id}>
								La categoria {category.name} dipende dalle palette di {dependsCategoryPalette.name}
								<div>
									<MappingCategoryPaletteColorsCategory
										key={dependsCategoryPalette.id}
										elementMappingCategoryPaletteColors={props.value}
										category={dependsCategoryPalette}
										realcategory={props.categories[categoryId]}
										svgColors={props.colors}
										palette={props.palette}
										onHighlight={props.onHighlight}
										onDeHighlight={props.onDeHighlight}
										resetMappingForCategory={() => resetMappingForCategory(dependsCategoryPalette.id)}
										addMappingCategoryPaletteColors={addMappingCategoryPaletteColors}
									/>
								</div>
							</div>
						)
					}

					components.push(
						<MappingCategoryPaletteColorsCategory
							key={categoryId}
							elementMappingCategoryPaletteColors={props.value}
							category={props.categories[categoryId]}
							realcategory={props.categories[categoryId]}
							svgColors={props.colors}
							palette={props.palette}
							onHighlight={props.onHighlight}
							onDeHighlight={props.onDeHighlight}
							addMappingCategoryPaletteColors={addMappingCategoryPaletteColors}
							resetMappingForCategory={() => resetMappingForCategory(categoryId)}
						/>
					)

					return components
				})}
			</div>
		</React.Fragment>
	)
}

export default React.memo(
	connect((state: IRootState) => ({
		palette: state.resources.palette,
		categories: state.resources.category,
	}))(MappingCategoryPaletteColorsComponent)
)
