import React from 'react'
import { IPublicUser, userScopes, UserScopes } from '@faceyourmanga/fym-lib'
import { IServerPagination } from '@faceyourmanga/fym-shared'
import { PrivateApi } from '@faceyourmanga/fym-shared'
import dispatchMessage from '../../messages/dispatchMessage'
import ConfirmPromise from '../../components/Confirm'

function User({ user, paginator }: { user: IPublicUser; paginator: IServerPagination<any, any> }) {
	async function toggleBan() {
		if (await ConfirmPromise(`You want ${user.banned ? 'unban' : 'ban'} user?`)) {
			try {
				await PrivateApi.put(`v1/users/${user.id}/ban`, !user.banned)
				paginator.reload()
			} catch (e: any) {
				dispatchMessage(`<h2>Errore salvataggio utente</h2><div>${e.data?.message || e.message}</div>`, {
					type: 'error',
				})
			}
		}
	}
	async function toggleScope(scope: UserScopes) {
		if (await ConfirmPromise(`You want ${user.scopes.includes(scope) ? 'remove' : 'add'} scope "${scope}" to user?`)) {
			try {
				const scopes = [...user.scopes]
				if (user.scopes.includes(scope)) {
					scopes.splice(scopes.indexOf(scope), 1)
				} else {
					scopes.push(scope)
				}

				await PrivateApi.put(`v1/users/${user.id}/scopes`, scopes)
				paginator.reload()
			} catch (e: any) {
				dispatchMessage(`<h2>Errore salvataggio utente</h2><div>${e.data?.message || e.message}</div>`, {
					type: 'error',
				})
			}
		}
	}

	return (
		<div className="users__list__item">
			<div>
				{user.id.substr(0, 2)}...{user.id.substr(-3)}
			</div>
			<div>{user.nickname}</div>
			<div>{user.username}</div>
			<div>
				<input type="checkbox" checked={user.banned} onChange={toggleBan} />
			</div>
			<div className="users__list__item__scopes">
				{userScopes.map(scope => (
					<div key={scope}>
						{scope} <input type="checkbox" checked={user.scopes.includes(scope)} onChange={() => toggleScope(scope)} />
					</div>
				))}
			</div>
		</div>
	)
}

export default React.memo(User)
