import { IResources } from '@faceyourmanga/fym-lib'
import { PrivateApi } from '@faceyourmanga/fym-shared'
import { Store } from 'redux'
import { importResourcesState } from './resources/actions'
import { bindWebsocket, closeWebsocket } from './websocket'

const bindResources = async (store: Store) => {
	try {
		const resources: IResources = await PrivateApi.get<IResources>('v1/resources')

		store.dispatch(importResourcesState(resources))
	} catch (e: any) {
		console.error(e)
	}
}

const clearResources = async (store: Store) => {
	try {
		store.dispatch(
			importResourcesState({
				transform: {},
				palette: {},
				set: {},
				group: {},
				category: {},
				element: {},
			})
		)
	} catch (e: any) {
		console.error(e)
	}
}

const bindServices = async (store: Store) => {
	bindResources(store)
	bindWebsocket(store.getState().hosts.ws, store)
}

const stopServices = async (store: Store) => {
	clearResources(store)
	closeWebsocket()
}

export { bindServices, stopServices }
