import { Color as PColor, ColorEntry } from '@faceyourmanga/fym-lib'
import * as React from 'react'

import Color from './Color'

interface IStaticPaletteView {
	value: ColorEntry | Array<PColor | undefined>
	noPrimary?: boolean
}

const StaticPaletteView = (props: IStaticPaletteView) => {
	if (Array.isArray(props.value)) {
		return (
			<div className="paletteRow paletteRow--static">
				{!props.noPrimary && (
					<div className="paletteRow__color">
						<Color color={props.value[0]} />
					</div>
				)}
				<div className="paletteRow__variationsContainer">
					<div className="paletteRow__variations">
						{props.value.map((v, i) => (
							<div key={i} className="paletteRow__variations__variation">
								<Color color={v} />
							</div>
						))}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="paletteRow paletteRow--static">
			{!props.noPrimary && (
				<div className="paletteRow__color">
					<Color color={props.value.colorId} />
				</div>
			)}
			<div className="paletteRow__variationsContainer">
				<div className="paletteRow__variations">
					{props.value.values.map((v, i) => (
						<div key={i} className="paletteRow__variations__variation">
							<Color color={v} />
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default StaticPaletteView
