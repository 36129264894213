import * as React from 'react'
import usePasteSVG from '../hooks/usePasteSVG'
import Dropzone from './Dropzone'

interface IUploadSVGProps {
	setSVG: (svg: string) => void
}

function UploadSVG(props: IUploadSVGProps) {
	const svgData = usePasteSVG()

	React.useEffect(() => {
		console.log('asdasd', svgData)
		if (svgData && svgData.raw) {
			props.setSVG(svgData.raw)
		}
	}, [svgData, props.setSVG])

	return <Dropzone accept="image/svg+xml" readType="text" placeholder="Drop here" onChange={svg => props.setSVG(svg)} />
}

export default UploadSVG
