import { ICategory, IResources } from '@faceyourmanga/fym-lib'
import * as React from 'react'
import { MdClose } from 'react-icons/md'
import SelectOrCreateResource from '../../../components/ResourcePath/SelectOrCreateResource'

interface CategoryFormDefaultElementTransformProps {
	resources: IResources
	category: Partial<ICategory>
	onChange: (name: string, value: any) => void
}

function CategoryFormDefaultElementTransform({
	category,
	resources,
	onChange,
}: CategoryFormDefaultElementTransformProps) {
	return (
		<div>
			Transform di default per gli elementi:
			<span style={{ marginLeft: '1rem' }}>
				{category.transform ? (
					<React.Fragment>
						{resources.transform[category.transform].name}
						<MdClose onClick={() => onChange('transform', undefined)} />
					</React.Fragment>
				) : (
					<SelectOrCreateResource
						type="transform"
						resources={Object.values(resources.transform)}
						onSelect={o => {
							onChange('transform', o.id)
						}}
					/>
				)}
			</span>
		</div>
	)
}

export default React.memo(CategoryFormDefaultElementTransform)
