import * as React from 'react'

const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
const CURRENT_YEAR = new Date().getFullYear()
const DAYS = new Array(31).fill(1).map((v, i) => v + i)
const YEARS = new Array(90).fill(CURRENT_YEAR - 16).map((v, i) => v - i)

interface IState {
	year: number
	month: number
	day: number
}

const now = new Date()

function SelectDate(props: { value?: string; onChange: (date: string) => void }) {
	function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const state = getCurrentState()

		state[e.target.name as keyof IState] = parseFloat(e.target.value || '')

		const dateStr = stateToString(state)

		if (isValidDate(dateStr)) {
			props.onChange(dateStr)
		}
	}

	function isValidDate(dateStr: string) {
		const date = Date.parse(dateStr)

		return !isNaN(date)
	}

	function getCurrentState(): IState {
		const parsed = props.value ? props.value.split('-') : [undefined, undefined, undefined]

		return {
			year: parseFloat((parsed[0] || CURRENT_YEAR) + ''),
			month: parseFloat((parsed[1] || now.getMonth()) + ''),
			day: parseFloat((parsed[2] || now.getDate()) + ''),
		}
	}

	function stateToString(state?: IState) {
		state = state || getCurrentState()

		return `${state.year}-${state.month}-${state.day}`
	}

	const state = getCurrentState()

	if (typeof props.value === 'undefined' || !isValidDate(props.value)) {
		props.onChange(stateToString(state))
	}

	return (
		<div className="select-birth">
			<select className="select" name="year" value={state.year} onChange={onChange}>
				{YEARS.map(year => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</select>
			<select className="select" name="month" value={state.month} onChange={onChange}>
				{MONTHS.map((month, i) => (
					<option key={month} value={i + 1}>
						{month}
					</option>
				))}
			</select>
			<select className="select" name="day" value={state.day} onChange={onChange}>
				{DAYS.map(day => (
					<option key={day} value={day}>
						{day}
					</option>
				))}
			</select>
		</div>
	)
}

export default React.memo(SelectDate)
