import React from 'react'

import { SVG } from '@faceyourmanga/fym-draw'
import dispatchMessage from '../messages/dispatchMessage'

export interface ISVGData {
	raw: string
	colors: Array<string>
}

export default function usePasteSVG(): ISVGData | null {
	const [svgData, setSVGData] = React.useState<ISVGData | null>(null)

	React.useEffect(() => {
		async function handlePaste(e: ClipboardEvent) {
			const data = e.clipboardData?.getData('text') || ''

			try {
				const optimized = await SVG.optimize(data)
				if (optimized && optimized.data && optimized.data.length > 0) {
					setSVGData({
						raw: optimized.data,
						colors: SVG.getColors(optimized.data),
					})
				}
			} catch (e: any) {
				dispatchMessage('Error import svg', { type: 'error' })
			}
		}

		document.addEventListener('paste', handlePaste, { passive: true })

		return () => document.removeEventListener('paste', handlePaste)
	}, [])

	return svgData
}
