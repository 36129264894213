import { MessagesActionTypes, SHOW_MESSAGE, HIDE_MESSAGE } from './types'

let message_id = 0

export function showMessage(message: string, data?: any): MessagesActionTypes & { message_id: number } {
	return {
		type: SHOW_MESSAGE,
		message,
		data,
		message_id: ++message_id,
	}
}

export function hideMessage(message_id: number): MessagesActionTypes {
	return {
		type: HIDE_MESSAGE,
		message_id,
	}
}
