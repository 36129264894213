import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Auth, ISharedAuthState, ISharedHostsState, PromiseButton } from '@faceyourmanga/fym-shared'
import { IUser, UserScopes } from '@faceyourmanga/fym-lib'

import { IRootState } from '../types/store'

interface ILogin {
	auth: ISharedAuthState
	hosts: ISharedHostsState
}

interface ILoginState {
	email: string
	password: string
}

function Login(props: ILogin) {
	const [state, setState] = React.useState<ILoginState>({ email: '', password: '' })
	const [error, setError] = React.useState<string | null>(null)

	if (Auth.check()) {
		const user = Auth.getUser() as IUser

		if (user.scopes.length <= 0) {
			window.location.href = props.hosts.frontend
			return null
		} else if (user.scopes.length === 1) {
			const scope: UserScopes = user.scopes[0]
			switch (scope) {
				case 'business':
					return <Redirect to={'/payments'} />
				case 'moderator':
					return <Redirect to={'/users'} />
				case 'editor':
					return <Redirect to={'/editor'} />
			}
		}
		return <Redirect to="/" />
	}

	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		setState({ ...state, [e.target.name]: e.target.value })
	}

	async function login() {
		setError(null)
		try {
			await Auth.signin(state.email, state.password)
		} catch (e: any) {
			console.log(e)
			setError('errore login')
		}
	}

	return (
		<div className="login">
			<div className="login__container">
				<img className="login__container__logo" src="/assets/images/logo.svg" alt="face your manga" />

				<input type="email" name="email" placeholder="email" onChange={onChange} />
				<input type="password" name="password" autoComplete="email" placeholder="password" onChange={onChange} />
				<PromiseButton onClick={login}>Accedi</PromiseButton>

				{error && <div>{error}</div>}
			</div>
		</div>
	)
}

export default connect((state: IRootState) => ({
	hosts: state.hosts,
	auth: state.auth,
}))(Login)
