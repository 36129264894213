export function blobToBase64(blob: Blob) {
	return new Promise<string>(resolve => {
		const reader = new FileReader()
		reader.readAsDataURL(blob)

		reader.onloadend = function () {
			resolve(reader.result as string)
		}
	})
}
