// min max step

import { IRange } from '@faceyourmanga/fym-lib/dist'
import * as React from 'react'
import Flex from '../components/Flex'

interface IRangeProps {
	value: IRange | undefined
	onChange: (value: IRange) => void
}

const Range = (props: IRangeProps) => {
	const value: IRange = { min: props.value?.min || 0, max: props.value?.max || 1, step: props.value?.step || 0.1 }

	return (
		<Flex gap="0.5rem">
			<Flex gap="0.5rem">
				min
				<input
					style={{ width: '4rem' }}
					type="number"
					value={value.min}
					step={value.step}
					onChange={e => props.onChange({ ...value, min: parseFloat(e.target.value) })}
				/>
			</Flex>
			<Flex gap="0.5rem">
				max
				<input
					style={{ width: '4rem' }}
					type="number"
					value={value.max}
					step={value.step}
					onChange={e => props.onChange({ ...value, max: parseFloat(e.target.value) })}
				/>
			</Flex>
			<Flex gap="0.5rem">
				step
				<input
					style={{ width: '4rem' }}
					type="number"
					value={value.step}
					onChange={e => props.onChange({ ...value, step: parseFloat(e.target.value) })}
				/>
			</Flex>
		</Flex>
	)
}

export default Range
