import { IMixed, IResources } from '@faceyourmanga/fym-lib'

export const IMPORT_RESOURCES_STATE = 'IMPORT_RESOURCES_STATE'
export const ADD_RESOURCE = 'ADD_RESOURCE'
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE'
export const UPDATE_RESOURCES = 'UPDATE_RESOURCES'
export const REMOVE_RESOURCE = 'REMOVE_RESOURCE'

interface ImportResourcesAction {
	type: typeof IMPORT_RESOURCES_STATE
	resources: IResources
}

interface AddResourceAction {
	type: typeof ADD_RESOURCE
	item_type: keyof IResources
	item: IMixed
}

interface UpdateResourceAction {
	type: typeof UPDATE_RESOURCE
	item_type: keyof IResources
	item: IMixed
}

interface UpdateResourcesAction {
	type: typeof UPDATE_RESOURCES
	item_type: keyof IResources
	items: Array<IMixed>
}

interface RemoveResourceAction {
	type: typeof REMOVE_RESOURCE
	item_type: keyof IResources
	item: IMixed
}

export type ResourcesActionTypes =
	| ImportResourcesAction
	| AddResourceAction
	| UpdateResourceAction
	| UpdateResourcesAction
	| RemoveResourceAction
