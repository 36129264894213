import * as React from 'react'
import Color from './Color'
import { ChromePicker } from 'react-color'
import Portal from './Portal'
import useRef from '../hooks/myUseRef'

interface IColorPickProps {
	value: string
	size?: number
	onChange?: (color: string) => void
}

const ColorPick = (props: IColorPickProps) => {
	const colorButtonRef = React.useRef<HTMLDivElement>(null)
	const [pickerRef, setPickerRef] = useRef<HTMLDivElement>(null)
	const [open, setOpen] = React.useState<{ x: number; y: number } | null>(null)
	const [tmpColor, setTmpColor] = React.useState<string>(props.value)

	function update() {
		props.onChange && props.onChange(tmpColor)
		setOpen(null)
	}

	React.useEffect(() => {
		setTmpColor(props.value)
	}, [props.value])

	React.useEffect(() => {
		function handleClick(e: MouseEvent) {
			if (pickerRef && pickerRef.current) {
				const target = e.target as HTMLElement

				if (target === colorButtonRef.current || colorButtonRef?.current?.contains(target)) {
					return
				}

				if (target !== pickerRef.current && !pickerRef?.current?.contains(target)) {
					setOpen(null)
				}
			}
		}

		document.addEventListener('click', handleClick, false)

		return () => document.removeEventListener('click', handleClick)
	}, [colorButtonRef])

	return (
		<div className="colorPick">
			{open && (
				<Portal container="#picker-root">
					<div ref={setPickerRef} className="colorPick__pick" style={{ top: open.y, left: open.x }}>
						<Pick color={tmpColor} onChange={setTmpColor} />

						{props.onChange && (
							<div className="colorPick__pick__save">
								<Color color={tmpColor} />
								<button className="button button--secondary" onClick={update}>
									Update
								</button>
							</div>
						)}
					</div>
				</Portal>
			)}

			<div className="colorPick__color" ref={colorButtonRef}>
				<Color
					color={props.value}
					onClick={(e: React.MouseEvent) =>
						open
							? setOpen(null)
							: setOpen({
									x: Math.min(e.clientX, window.innerWidth - 400),
									y: Math.min(e.clientY, window.innerHeight - 400),
							  })
					}
				/>
			</div>
		</div>
	)
}

const Pick = React.memo(({ color, onChange }: { color: string; onChange: (color: string) => void }) => (
	<ChromePicker color={color} onChange={color => onChange(color.hex)} />
))

export default React.memo(ColorPick)
