import * as React from 'react'
import Modal from '../../../components/Modal'
import Palette from '../palette/Palette'

interface ICreatorPaletteProps {
	open: boolean
	setOpen: (status: boolean) => void
}

function CreatorPalette(props: ICreatorPaletteProps) {
	return (
		<Modal nopadding open={props.open} close={() => props.setOpen(false)}>
			<Palette />
		</Modal>
	)
}

export default React.memo(CreatorPalette)
